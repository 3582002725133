import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get policy list
export const get_policy_list = createAsyncThunk(
  'medicalSettings/get_policy_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/policy/get`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update policy
export const update_policy = createAsyncThunk(
  'medicalSettings/update_policy',
  async (
    { payload, showNotification, setShowEditorForEdit, handleGetData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/admin/policy/update`, payload)
      if (response.status === 0) {
        setShowEditorForEdit(false)
        handleGetData()
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
