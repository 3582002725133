// Second Opinion Service
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import axiosInstance from 'axiosInstance'
import { translate } from 'i18n/i18n'

import { displayMultipleNotifications } from 'utils/notificationsUtils'

// Get Organs
export const get_organ_list = createAsyncThunk(
  'so/organ_list',
  async ({ showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/so/organ-list`)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Get Diagnosis
export const get_diagnosis_list = createAsyncThunk(
  'so/diagnosis_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `v1/so/diagnosis-list?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}`,
        payload
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Create SO case
export const create_so_case = createAsyncThunk(
  'so/create_so_case',
  async (
    { payload, showNotification, handleNextClick, isSaveDraft, navigate },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/so/create-case`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        if (!isSaveDraft) {
          handleNextClick()
        } else {
          navigate(AppRoutingConfig.MY_LIBRARY)
        }
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Get Request Info
export const fetch_request_info = createAsyncThunk(
  'so/fetch_request_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/request-info`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Get Payment Info
export const get_payment_info = createAsyncThunk(
  'so/get_payment_info',
  async ({ dataToBeSent, onPaymentPage, handleNextClick }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/payment-info`, dataToBeSent)
      if (response.status === 0) {
        if (!onPaymentPage) {
          handleNextClick()
        }
        return { response: response.data }
      }
      if (response.status === 1) {
        return { response: response.data }
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Add Request Info
export const add_request_info = createAsyncThunk(
  'so/add_request_info',
  async (
    { payload, showNotification, handleNextClick, isSaveDraft, navigate, dispatch },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/so/add-request-info`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        if (!isSaveDraft) {
          let dataToBeSent = { case_id: payload.case_id }
          let onPaymentPage = false
          dispatch(get_payment_info({ dataToBeSent, onPaymentPage, handleNextClick }))
        } else {
          navigate(AppRoutingConfig.MY_LIBRARY)
        }
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Make payment
export const make_payment = createAsyncThunk(
  'so/make_payment',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/payment`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })

        navigate(AppRoutingConfig.CASE_PAYMENT_CONFIRMATION)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Get drafted cases
export const get_drafted_so_cases = createAsyncThunk(
  'so/get_drafted_so_cases',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/so/draft/get-all?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}`,
        payload
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Get drafted case details
export const get_drafted_details = createAsyncThunk(
  'so/get_drafted_details',
  async ({ payload, showNotification, navigate, dispatch }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/draft/get-data`, payload)
      if (response.status === 0) {
        if (response?.data?.community?.community_id) {
          let dataToBeSent = { community_id: response?.data?.community?.community_id }
          dispatch(
            get_community_profile_info_for_drafted_cases({
              payload: dataToBeSent,
              showNotification,
              navigate
            })
          )
        } else {
          let dataToBeSent = { user_no: response?.data?.expert_data?.user_no }
          dispatch(
            get_physician_profile_info_for_drafted_cases({
              payload: dataToBeSent,
              showNotification,
              navigate
            })
          )
        }

        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get profile info
export const get_physician_profile_info_for_drafted_cases = createAsyncThunk(
  'so_case/get_physician_profile_info_for_drafted_cases',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/physician/detail`, payload)
      if (response.status === 0) {
        navigate(AppRoutingConfig.CREATE_SECOND_OPINION_CASE)
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community profile info
export const get_community_profile_info_for_drafted_cases = createAsyncThunk(
  'so_case/get_community_profile_info_for_drafted_cases',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/view`, payload)
      if (response.status === 0) {
        navigate(AppRoutingConfig.CREATE_SECOND_OPINION_CASE)
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
