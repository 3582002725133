import RequireAuth from 'app/requireAuth'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import MainLayout from 'components/layouts/main-layout'
import { roles } from '../../constants'
import UserDetailsProvider from 'context/UserDetailsProvider'
import * as lazy from './lazyComponents'
import PrimaryLayout from 'components/layouts/primary-layout'
import ErrorBoundary from 'components/common/error-boundary'
import { Navigate } from 'react-router-dom'
import BlueShadeLayout from 'components/layouts/blue-shade-layout'

const CommonWrapper = ({ Layout }) => {
  return (
    <UserDetailsProvider>
      <RequireAuth allowedRoles={[roles.superAdmin]}>
        <Layout />
      </RequireAuth>
    </UserDetailsProvider>
  )
}

export const synapp_admin_routes = [
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <CommonWrapper Layout={MainLayout} />,
    children: [
      {
        path: AppRoutingConfig.ACTIVITY_MONITORING,
        element: <lazy.ActivityMonitoring />
      }
    ]
  },
  {
    path: AppRoutingConfig.SETTINGS,
    element: <CommonWrapper Layout={PrimaryLayout} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutingConfig.MY_PROFILE} replace />
      },
      {
        path: AppRoutingConfig.MY_PROFILE,
        element: <lazy.ProfileInformation />
      },
      {
        path: AppRoutingConfig.ADMINISTRATION,
        children: [
          {
            index: true,
            element: <Navigate to={AppRoutingConfig.ALL_USERS} replace />
          },
          {
            path: AppRoutingConfig.ALL_USERS,
            element: <lazy.AllUsers />
          },
          {
            path: AppRoutingConfig.MEDICAL_SETTINGS,
            element: <lazy.MedicalSettings />
          },
          {
            path: AppRoutingConfig.QUESTION_LIBRARY,
            element: <lazy.QuestionLibrary />
          },
          {
            path: AppRoutingConfig.INSTRUCTIONS,
            element: <lazy.Instructions />
          },
          {
            path: AppRoutingConfig.USER_ROLES_AND_PERMISSIONS,
            element: <lazy.UserRolesAndPermissions />
          }
        ]
      },
      {
        path: AppRoutingConfig.CONFIGURE_POLICIES,
        element: <lazy.ConfigurePolicies />
      }
    ]
  },
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <CommonWrapper Layout={BlueShadeLayout} />,
    children: [
      {
        path: AppRoutingConfig.ADD_USER_MANUALLY,
        element: <lazy.ManualUserEntry />
      },
      {
        path: AppRoutingConfig.ADD_USER_MANUALLY_CONFIRMATION,
        element: <lazy.ManualUserEntryConfirmation />
      },
      {
        path: AppRoutingConfig.CREATE_COMMUNITY,
        element: <lazy.CreateCommunity />
      },
      {
        path: AppRoutingConfig.ADD_COMMUNITY_MEMBER_CONFIRMATION,
        element: <lazy.AddCommunityMemberConfirmation />
      }
    ]
  }
]
