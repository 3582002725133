import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'

export const synappAdminSettingsSidebarUtils = (t) => {
  let menuGroup = [
    {
      id: 'my-profile',
      title: t('AppModuleNames.MyProfile'),
      url: AppRoutingConfig.MY_PROFILE
    },
    {
      id: 'administration',
      title: t('AppModuleNames.Administration'),
      children: [
        {
          id: 'all-users',
          title: t('AppModuleNames.AllUsers'),
          url: AppRoutingConfig.ALL_USERS
        },
        {
          id: 'user-roles-and-permissions',
          title: t('AppModuleNames.UserRolesAndPermissions'),
          url: AppRoutingConfig.USER_ROLES_AND_PERMISSIONS
        },
        {
          id: 'medical-settings',
          title: t('AppModuleNames.MedicalSettings'),
          url: AppRoutingConfig.MEDICAL_SETTINGS
        },
        {
          id: 'question-library',
          title: t('AppModuleNames.QuestionLibrary'),
          url: AppRoutingConfig.QUESTION_LIBRARY
        },
        {
          id: 'instructions',
          title: t('AppModuleNames.Instructions'),
          url: AppRoutingConfig.INSTRUCTIONS
        }
      ]
    },
    // {
    //   id: 'languages',
    //   title: t('AppModuleNames.Languages'),
    //   url: AppRoutingConfig.LANGUAGES
    // },
    {
      id: 'configure-policies',
      title: t('AppModuleNames.ConfigurePolicies'),
      url: AppRoutingConfig.CONFIGURE_POLICIES
    }
    // {
    //   id: 'interface-management',
    //   title: t('AppModuleNames.InterfaceManagement'),
    //   url: AppRoutingConfig.INTERFACE_MANAGEMENT
    // },
    // {
    //   id: 'business-model',
    //   title: t('AppModuleNames.BusinessModel'),
    //   url: AppRoutingConfig.BUSINESS_MODEL
    // },
    // {
    //   id: 'user-and-community-plans',
    //   title: t('AppModuleNames.UserAndCommunityPlans'),
    //   url: AppRoutingConfig.USER_AND_COMMUNITY_PLANS
    // }
  ]

  return menuGroup
}
