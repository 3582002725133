import { Avatar, Flex, List, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { listData } from './Data'
import './NotificationWidget.scss'
import { translate } from 'i18n/i18n'

const NotificationList = ({ listData }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={listData}
      renderItem={(item) => (
        <List.Item className="notification-list">
          <List.Item.Meta
            avatar={<Avatar size={40} icon={<UserOutlined />} />}
            title={<Typography.Title level={3}>{item.heading}</Typography.Title>}
            description={
              <>
                <Flex vertical gap={'0.5rem'}>
                  <Typography.Title level={4} type="secondary" style={{ fontWeight: 400 }}>
                    {item.notificationText}
                  </Typography.Title>
                  {item.notificationDescription?.length && (
                    <Typography.Title
                      level={4}
                      type="secondary"
                      style={{ fontWeight: 400, margin: 0 }}
                      className="notification-description"
                    >
                      {item.notificationDescription}
                    </Typography.Title>
                  )}
                  <Typography.Title
                    level={4}
                    type="secondary"
                    style={{ fontWeight: 400, margin: 0 }}
                  >
                    {item.notificationTime}
                  </Typography.Title>
                </Flex>
              </>
            }
          />
        </List.Item>
      )}
    />
  )
}

export const tabItems = [
  {
    key: '1',
    label: translate('Notifications.All'),
    children: <NotificationList listData={listData} />
  },
  {
    key: '2',
    label: translate('Notifications.Telexpertise'),
    children: <h1>Telexpertise</h1>
  },
  {
    key: '3',
    label: translate('Notifications.SecondOpinion'),
    children: <NotificationList listData={listData} />
  }
]
