import { createSlice } from '@reduxjs/toolkit'

export const physiciansSlice = createSlice({
  name: 'physicians',
  initialState: {
    physicianStep: 0
  },
  reducers: {
    navigateToNextForm: (state, action) => {
      state.physicianStep = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { navigateToNextForm } = physiciansSlice.actions

export default physiciansSlice.reducer
