import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Dropdown,
  Flex,
  Row,
  Select,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import BreadCrumbs from '../bread-crumbs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { SearchOutlined, UserOutlined, ArrowLeftOutlined, ReadOutlined } from '@ant-design/icons'
import { ArrowDownIcon, GlobalIcon, NotificationIcon } from 'assets/svg'
import VerifiedBadge from '../verified-badge/VerifiedBadge'
import { useTranslation } from 'react-i18next'
import { optionsData } from './Data'
import SelectableDropdownButton from '../selectable-dropdown-button'
import NotificationWidget from '../notification-widget/Index'
import useClickOutside from 'hooks/useClickOutside'
import { useTourComponentRefs } from 'context/TourRefsProvider'
import { useNavigate } from 'react-router-dom'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import SynappTourDialog from './SynappTourDialog'
import { roles } from '../../../constants'
import { useUserDetails } from 'context/UserDetailsProvider'
import { AuthService, CommonService } from '../../../redux/services'
import { useDispatch, useSelector } from 'react-redux'
import { useNotify } from 'context/NotificationProvider'
import { encryptData } from 'assets/config/AppEncryptDecryptConfig'
import { setActiveProfile } from '../../../redux/reducer/activeProfileReducer'
import { clearCommonDataDetails } from '../../../redux/reducer/commonReducer'
import './Navbar.scss'

const Navbar = ({ isPrimaryLayout, sidebarCollapsed, setSidebarCollapsed }) => {
  /* user details dependencies */
  const { selectedRole, userDetails, selectedProfileDetails } = useUserDetails()
  /* user details dependencies */

  console.log({ selectedRole, userDetails, selectedProfileDetails })

  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* define ref */
  const notificationRef = useRef(null)
  /* define ref */

  /* dispatch dependencies */
  const dispatch = useDispatch()
  const { getCommunity } = useSelector((state) => state.common)
  /* dispatch dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* navigation dependencies */
  const navigate = useNavigate()
  /* navigation dependencies */

  /* variabale verfied badge */
  const showVerifiedBadge = true
  /* variabale verfied badge */

  const ref = useTourComponentRefs()

  /* define state */
  const [searchValue, setSearchValue] = useState(null)
  const [languageValue, setLanguageValue] = useState('')
  const [showContent, setShowContent] = useState(true)
  const { activeProfile } = useSelector((state) => state.activeProfile)
  /* define state */

  console.log(activeProfile, 'activeProfile')

  /* collapsed dependencies */
  const [collapsed, setCollapsed] = useState(false)
  // console.log('collapsed', collapsed)
  /* collapsed dependencies */

  /* dialog dependencies */
  const [synappTourDialogOpen, setSynappTourDialogOpen] = useState(false)
  /* dialog dependencies */

  useEffect(() => {
    function handleResizeFunction(e) {
      if (e.currentTarget.innerWidth <= 768) {
        setShowContent(false)
      } else {
        setShowContent(true)
      }
    }

    // Set initial state based on the current window width
    if (window.innerWidth <= 768) {
      setShowContent(false)
    } else {
      setShowContent(true)
    }

    window.addEventListener('resize', handleResizeFunction)
    return () => {
      window.removeEventListener('resize', handleResizeFunction)
    }
  }, [])

  const handleOpenSynappTourDialog = () => {
    setSynappTourDialogOpen(true)
  }

  const handleCloseSynappTourDialog = () => {
    setSynappTourDialogOpen(false)
  }

  const synappTourDialogProps = useMemo(
    () => ({
      open: synappTourDialogOpen,
      onClose: handleCloseSynappTourDialog
    }),
    [synappTourDialogOpen]
  )
  /* dialog dependencies */

  /* check for outside click to close the widget */
  useClickOutside(notificationRef, () => setCollapsed(false))
  /* check for outside click to close the widget */

  /* Handle Search Change */
  const handleChange = (newValue) => {
    setSearchValue(newValue)
  }
  /* Handle Search Change */

  const navigateBackButtonBasedOnRole = {
    [roles.superAdmin]: AppRoutingConfig.DASHBOARD,
    [roles.physician]: AppRoutingConfig.HOME,
    [roles.editor]: AppRoutingConfig.HOME,
    [roles.author]: AppRoutingConfig.HOME,
    [roles.patient]: AppRoutingConfig.HOME
  }

  const navigationRoute = navigateBackButtonBasedOnRole[selectedRole]

  /* user dependencies */
  const userDropdownItems = [
    {
      key: '1',
      label: t('SignIn.GoToProfile')
    },
    {
      key: '2',
      label: t('SignIn.Logout')
    }
  ]
  /* user dependencies */

  const handleGetData = () => {
    dispatch(CommonService.get_community({ payload: {}, showNotification, userDetails }))
  }

  useEffect(() => {
    console.log(getCommunity?.data, 'getCommunity?.data')
    if (!getCommunity?.data) handleGetData()
    dispatch(setActiveProfile(selectedProfileDetails))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={`navbar-container navbar-wrapper ${isPrimaryLayout ? 'primary-navbar' : 'main-navbar'}`}
    >
      <Row gutter={[10, 10]} style={{ alignItems: 'center' }}>
        <Col
          xs={23}
          sm={2}
          md={!isPrimaryLayout ? (sidebarCollapsed ? 6 : 5) : 6}
          lg={!isPrimaryLayout ? (sidebarCollapsed ? 6 : 5) : 6}
          xl={5}
          xxl={6}
          className="breadcrumb-column-container"
        >
          <Flex align="center" gap="1rem">
            <BreadCrumbs isPrimaryLayout={isPrimaryLayout} />
          </Flex>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={!isPrimaryLayout ? (sidebarCollapsed ? 24 : 24) : 24}
          lg={!isPrimaryLayout ? (sidebarCollapsed ? 24 : 24) : 24}
          xl={19}
          xxl={18}
        >
          <Row gutter={[20, 20]} style={{ alignItems: 'center' }}>
            {isPrimaryLayout && (
              <Col xs={4} sm={2} md={1} lg={1} xl={1} xxl={1}>
                <Button
                  shape="circle"
                  style={{ width: 30, aspectRatio: 1 }}
                  icon={<ArrowLeftOutlined />}
                  size="large"
                  onClick={() => navigate(navigationRoute)}
                />
              </Col>
            )}
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 24 : 24) : 9, order: 1 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 7 : 7) : 7, order: 1 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 7 : 7) : 8, order: 1 }}
              xl={{ span: 7, order: 1 }}
              xxl={{ span: 7, order: 1 }}
            >
              <Select
                showSearch
                value={searchValue}
                placeholder={
                  <div style={{ color: isPrimaryLayout && 'var(--pure-white)' }}>
                    <SearchOutlined style={{ color: isPrimaryLayout && 'var(--pure-white)' }} />
                    &nbsp; {t('CommonUtils.Search')}
                  </div>
                }
                defaultActiveFirstOption={false}
                suffixIcon={null}
                onChange={handleChange}
                options={optionsData}
                style={{
                  width: '100%',
                  backgroundColor: isPrimaryLayout
                    ? 'var(--primary-dark-color)'
                    : 'var(--silver-sand)',
                  color: isPrimaryLayout && 'var(--pure-white) !important',
                  borderRadius: 12
                }}
                variant="borderless"
              />
            </Col>

            <Col
              xs={{ span: 17, order: 2 }}
              sm={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 10 : 9) : 9, order: 1 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 6 : 6) : 6, order: 1 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 6 : 5) : 6, order: 1 }}
              xl={{ span: 5, order: 1 }}
              xxl={{ span: 6, order: 1 }}
            >
              {![roles.superAdmin, roles.patient].includes(selectedRole) && (
                <Select
                  defaultActiveFirstOption
                  value={activeProfile}
                  options={getCommunity?.data}
                  style={{
                    width: '100%',
                    backgroundColor: isPrimaryLayout
                      ? 'var(--primary-dark-color)'
                      : 'var(--silver-sand)',
                    color: isPrimaryLayout && 'var(--pure-white) !important',
                    borderRadius: 12
                  }}
                  variant="borderless"
                  optionRender={(option) => {
                    const { data } = option
                    return data?.label === 'Individual' ? (
                      <Typography.Title level={4} style={{ margin: 0 }}>
                        {data?.label}
                      </Typography.Title>
                    ) : (
                      <Flex gap="0.5rem">
                        <Flex style={{ minWidth: 30 }} align="center" justify="center">
                          {<Avatar icon={<UserOutlined />} size={30} src={data?.community_photo} />}
                        </Flex>
                        <Flex gap="0.5rem" vertical>
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {data?.label}
                          </Typography.Title>
                          <Typography.Paragraph style={{ textTransform: 'capitalize', margin: 0 }}>
                            {data?.community_members?.[0]?.role}
                          </Typography.Paragraph>
                        </Flex>
                      </Flex>
                    )
                  }}
                  onChange={(newValue) => {
                    const selectedProfiledVal = getCommunity?.data?.filter(
                      (element) => element.id === newValue
                    )
                    const selectedProfile = selectedProfiledVal[selectedProfiledVal?.length - 1]
                    console.log(selectedProfile, 'selectedProfile')
                    if (selectedProfile?.label === 'Individual') {
                      dispatch(
                        CommonService.community_revoke_token({
                          payload: {},
                          showNotification,
                          navigate
                        })
                      )
                    } else {
                      dispatch(
                        CommonService.community_create_token({
                          payload: { community_id: selectedProfile?.id },
                          showNotification,
                          navigate
                        })
                      )
                    }
                    dispatch(setActiveProfile(selectedProfile))
                    encryptData('selectedProfile', selectedProfile)
                  }}
                  className={isPrimaryLayout && 'profile-selection-dropdown-white'}
                />
              )}
            </Col>

            <Col
              xs={{ span: 7, order: 3 }}
              sm={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 5 : 6) : 6, order: 2 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 4 : 4) : 4, order: 2 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 5 : 5) : 4, order: 2 }}
              xl={{ span: 5, order: 2 }}
              xxl={{ span: 5, order: 2 }}
            >
              <Flex align="center" justify="center">
                <SelectableDropdownButton
                  menuItems={[
                    {
                      key: '1',
                      label: window.innerWidth >= 1025 ? t('CommonUtils.English') : 'EN'
                    },
                    {
                      key: '2',
                      label: window.innerWidth >= 1025 ? t('CommonUtils.French') : 'FR'
                    }
                  ]}
                  suffixIcon={<GlobalIcon color={isPrimaryLayout && 'var(--pure-white)'} />}
                  value={
                    languageValue ||
                    (window.innerWidth >= 1025 && t('CommonUtils.Validations.Select'))
                  }
                  setValue={setLanguageValue}
                  buttonProps={{
                    style: {
                      color: isPrimaryLayout && 'var(--pure-white)'
                    }
                  }}
                />
                <Divider
                  type="vertical"
                  style={{
                    height: '40px',
                    backgroundColor: isPrimaryLayout && 'var(--pure-white)'
                  }}
                />
                <Flex gap="0.2rem" align="center" justify="center">
                  {selectedRole === roles.physician && !isPrimaryLayout && (
                    <div
                      className={`tour-container ${!isPrimaryLayout && sidebarCollapsed ? 'tour-container-hidden' : ''}`}
                    >
                      <Tooltip title="App Tutorial">
                        <Button
                          icon={
                            <ReadOutlined
                              style={{
                                fontSize: 18,
                                color: isPrimaryLayout && 'var(--pure-white)'
                              }}
                            />
                          }
                          shape="circle"
                          type="text"
                          style={{ width: 40, aspectRatio: 1 }}
                          onClick={handleOpenSynappTourDialog}
                        />
                      </Tooltip>
                    </div>
                  )}
                  <div
                    className="notification-container"
                    ref={showContent ? notificationRef : null}
                  >
                    <NotificationWidget
                      isCollapsed={collapsed}
                      setCollapsed={setCollapsed}
                      showContent={showContent}
                      setShowContent={setShowContent}
                    />
                    <GetCustomTooltipConditionally title="Notifications" showTooltip={showContent}>
                      <Button
                        icon={
                          <Badge count={0} showZero>
                            <NotificationIcon color={isPrimaryLayout && 'var(--pure-white)'} />
                          </Badge>
                        }
                        shape="circle"
                        type="text"
                        style={{ width: 40, aspectRatio: 1 }}
                        onClick={() => {
                          setSidebarCollapsed && !showContent && setSidebarCollapsed(true)
                          setCollapsed((prevState) => !prevState)
                        }}
                      />
                    </GetCustomTooltipConditionally>
                  </div>
                </Flex>
              </Flex>
            </Col>

            <Col
              xs={{ span: !isPrimaryLayout ? 24 : 20, order: 1 }}
              sm={{ span: 9, order: 3 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 7 : 7) : 6, order: 3 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 6 : 6) : 5, order: 3 }}
              xl={{ span: 6, order: 3 }}
              xxl={{ span: 5, order: 3 }}
              className="profile-container"
            >
              <Dropdown
                menu={{
                  items: userDropdownItems,
                  onClick: (event) => {
                    const clickedItem = userDropdownItems?.find((item) => item.key === event.key)
                    if (clickedItem.key === '1') navigate(AppRoutingConfig.PROFILE_INFORMATION)
                    if (clickedItem.key === '2') {
                      localStorage.clear()
                      navigate(AppRoutingConfig.APP_URL_LOGIN)
                      dispatch(AuthService.logout({ payload: {}, showNotification, navigate }))
                      dispatch(clearCommonDataDetails())
                    }
                  }
                }}
                trigger={['click']}
                placement="bottomCenter"
                arrow={{ pointAtCenter: true }}
              >
                <Flex gap="0.5rem" ref={ref?.ref1} align="center">
                  <div style={{ width: 50 }}>
                    {selectedRole === roles.physician && showVerifiedBadge ? (
                      <VerifiedBadge size={40} />
                    ) : (
                      <Avatar icon={<UserOutlined />} size={40} />
                    )}
                  </div>
                  <Flex vertical gap={selectedRole === roles.physician ? 0 : '0.2rem'}>
                    <Typography.Title
                      level={4}
                      style={{ color: isPrimaryLayout && 'var(--pure-white)' }}
                      ellipsis={true}
                    >
                      {userDetails &&
                        `${userDetails?.title || ''} ${userDetails?.first_name} ${userDetails?.last_name}`}
                    </Typography.Title>
                    {selectedRole === roles.physician ? (
                      <Button
                        type="text"
                        style={{
                          padding: 0,
                          height: 20,
                          color: isPrimaryLayout && 'var(--pure-white)'
                        }}
                      >
                        <Flex align="center" gap="0.5rem">
                          <Badge status="success" />
                          {t('CommonUtils.Available')}
                          <ArrowDownIcon color={isPrimaryLayout && 'var(--pure-white)'} />
                        </Flex>
                      </Button>
                    ) : selectedRole === roles.superAdmin ? (
                      <Tag color="blue" style={{ borderRadius: 6, width: 'fit-content' }}>
                        {t('CommonUtils.SuperAdmin')}
                      </Tag>
                    ) : [roles.author, roles.editor].includes(selectedRole) ? (
                      <>
                        <Flex align="center" gap="0.5rem" style={{ width: '100%' }}>
                          <Typography.Paragraph
                            style={{
                              textTransform: 'capitalize',
                              margin: 0,
                              color: isPrimaryLayout && 'var(--pure-white)'
                            }}
                          >
                            {selectedRole} | {selectedProfileDetails?.community_title}
                          </Typography.Paragraph>
                        </Flex>
                      </>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Flex>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <SynappTourDialog {...synappTourDialogProps} />
    </div>
  )
}

const GetCustomTooltipConditionally = ({ children, showTooltip, title }) => {
  return showTooltip ? <Tooltip title={title}>{children}</Tooltip> : children
}

export default Navbar
