import { lazy } from 'react'

// auth
export const Login = lazy(() => import('../../components/pages/auth/login'))
export const Signup = lazy(() => import('../../components/pages/auth/signup'))
export const SignupWithRpps = lazy(() => import('../../components/pages/auth/signup-with-rpps'))
export const CreatePassword = lazy(() => import('../../components/pages/auth/create-password'))
export const ChooseYourIdentity = lazy(
  () => import('../../components/pages/auth/choose-your-identity')
)
export const VerifyYourIdentity = lazy(
  () => import('../../components/pages/auth/verify-your-identity')
)
export const AccountInformation = lazy(
  () => import('../../components/pages/auth/account-information')
)
export const PatientAccountInformation = lazy(
  () => import('../../components/pages/auth/patient-account-information')
)
export const LoginConfirmaiton = lazy(
  () => import('../../components/pages/auth/login-confirmation')
)
export const SignedUpConfirmaiton = lazy(
  () => import('../../components/pages/auth/signed-up-confirmation')
)
export const SelectSignupMethod = lazy(
  () => import('../../components/pages/auth/select-signup-method')
)
// features
export const Home = lazy(() => import('../../components/pages/features/home'))
export const UpgradeSubscriptionPlan = lazy(
  () => import('../../components/pages/features/home/upgrade-subscription-plan')
)
export const Dashboard = lazy(() => import('../../components/pages/features/dashboard'))
export const MyRequests = lazy(() => import('../../components/pages/features/my-requests'))
export const MyLibrary = lazy(() => import('../../components/pages/features/my-library'))
export const MyPatients = lazy(() => import('../../components/pages/features/my-patients'))
export const MyCommunity = lazy(() => import('../../components/pages/features/my-community'))

// Physician Settings
export const ProfileInformation = lazy(
  () => import('../../components/pages/features/settings/profile-information')
)
export const ProfessionalInformation = lazy(
  () => import('../../components/pages/features/settings/professional-information')
)
export const AccountSettings = lazy(
  () => import('../../components/pages/features/settings/account-settings')
)
export const CreateTemplate = lazy(
  () => import('../../components/pages/features/settings/account-settings/create-template')
)
export const RequestsManagement = lazy(
  () => import('../../components/pages/features/settings/requests-management')
)
export const Notifications = lazy(
  () => import('../../components/pages/features/settings/notifications')
)
export const BillingInfo = lazy(
  () => import('../../components/pages/features/settings/billing-info')
)
export const TestUsers = lazy(() => import('../../components/pages/features/settings/test-users'))
export const TestPhysicians = lazy(
  () => import('../../components/pages/features/settings/test-physicians')
)

// Patient
export const PatientHome = lazy(() => import('../../components/pages/features/patient/home'))
export const Appointments = lazy(
  () => import('../../components/pages/features/patient/appointments')
)
export const PatientMyRequests = lazy(
  () => import('../../components/pages/features/patient/my-requests')
)
export const PatientMyLibrary = lazy(
  () => import('../../components/pages/features/patient/my-library')
)
export const PatientProfileInformation = lazy(
  () => import('../../components/pages/features/patient/settings/profile-information')
)
export const PaymentInformation = lazy(
  () => import('../../components/pages/features/patient/settings/payment-information')
)

// Author and editor
export const AuthorEditorHome = lazy(
  () => import('../../components/pages/features/author-editor/home')
)

export const AuthorEditorDashboard = lazy(
  () => import('../../components/pages/features/author-editor/dashboard')
)

export const AllPatients = lazy(
  () => import('../../components/pages/features/author-editor/all-patients')
)

export const AllRequests = lazy(
  () => import('../../components/pages/features/author-editor/all-requests')
)

export const AuthorEditorMyCommunity = lazy(
  () => import('../../components/pages/features/author-editor/my-community')
)

// Synapp Admin
export const SynappAdminDashboard = lazy(
  () => import('../../components/pages/features/synapp-admin/dashboard')
)

export const SynappAdminMyRequests = lazy(
  () => import('../../components/pages/features/synapp-admin/my-requests')
)

export const ActivityMonitoring = lazy(
  () => import('../../components/pages/features/synapp-admin/activity-monitoring')
)

export const AllUsers = lazy(
  () => import('../../components/pages/features/synapp-admin/settings/administration/all-users')
)

export const MedicalSettings = lazy(
  () =>
    import('../../components/pages/features/synapp-admin/settings/administration/medical-settings')
)

export const QuestionLibrary = lazy(
  () =>
    import('../../components/pages/features/synapp-admin/settings/administration/question-library')
)

export const Instructions = lazy(
  () => import('../../components/pages/features/synapp-admin/settings/administration/instructions')
)

export const UserRolesAndPermissions = lazy(
  () =>
    import(
      '../../components/pages/features/synapp-admin/settings/administration/user-roles-and-permissions'
    )
)

export const ManualUserEntry = lazy(
  () =>
    import(
      '../../components/pages/features/synapp-admin/settings/administration/all-users/physicians/manual-user-entry/ManualUserEntry'
    )
)
export const ManualUserEntryConfirmation = lazy(
  () =>
    import(
      '../../components/pages/features/synapp-admin/settings/administration/all-users/physicians/manual-user-entry/ManualUserEntryConfirmation'
    )
)

export const ConfigurePolicies = lazy(
  () => import('../../components/pages/features/synapp-admin/settings/configure-policies')
)

// page not found
export const PageNotFound = lazy(() => import('../../components/pages/pageNotFound'))

export const CreateCase = lazy(() => import('../../components/pages/features/createCase'))

export const Directory = lazy(() => import('../../components/pages/features/directory'))

export const DocumentUploader = lazy(
  () => import('../../components/pages/features/createCase/CaseInfo/P2PQR/DocumentUploader')
)

export const PaymentConfirmaiton = lazy(
  () => import('../../components/pages/features/createCase/PaymentConfirmation')
)

export const ViewProfile = lazy(() => import('../../components/common/view-profile'))

export const ViewCommunity = lazy(() => import('../../components/common/view-community'))

export const ViewCase = lazy(() => import('../../components/pages/features/view-case'))

export const ViewSOCase = lazy(() => import('../../components/pages/features/view-so-case'))

export const ViewPhysicianProfile = lazy(
  () =>
    import(
      '../../components/pages/features/synapp-admin/settings/administration/all-users/physicians/view-profile'
    )
)

export const ViewActivityLogs = lazy(
  () =>
    import(
      '../../components/pages/features/synapp-admin/settings/administration/all-users/physicians/view-activity-logs'
    )
)

export const CreateCommunity = lazy(
  () =>
    import(
      '../../components/pages/features/synapp-admin/settings/administration/all-users/communities/create-community'
    )
)

export const AddCommunityMemberConfirmation = lazy(
  () =>
    import(
      '../../components/pages/features/synapp-admin/settings/administration/all-users/communities/create-community/AddCommunityMemberConfirmation'
    )
)
export const CreateSecondOpinionCase = lazy(
  () => import('../../components/pages/features/secondOpinion/CreateSecondOpinionCase')
)

export const SODocumentUploader = lazy(
  () => import('../../components/pages/features/secondOpinion/RequestInfo/P2PQR/DocumentUploader')
)

export const ResearcherCase = lazy(() => import('../../components/pages/features/researcherCase'))
