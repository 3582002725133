import { CloseOutlined } from '@ant-design/icons'
import { Badge, Button, Divider, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const NotificationWidgetHeader = ({ showContent, onClose }) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  return (
    <>
      <Flex align="center" justify="space-between" style={{ padding: '20px 20px 10px 20px' }}>
        <Flex align="center" gap={'0.5rem'}>
          <Typography.Title level={2}>{t('Notifications.Notifications')}</Typography.Title>
          <Badge count={3} showZero />
        </Flex>
        <Flex align="center" gap={'0.5rem'}>
          <Typography.Link>{t('Notifications.MarkAllAsRead')}</Typography.Link>
          {!showContent && (
            <Button type="text" icon={<CloseOutlined />} onClick={() => onClose()} />
          )}
        </Flex>
      </Flex>
      {showContent && <Divider />}
    </>
  )
}

export default NotificationWidgetHeader
