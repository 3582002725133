import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import CaseLayout from 'components/layouts/case-layout'
import UserDetailsProvider from 'context/UserDetailsProvider'
import * as lazy from './lazyComponents'

const CommonWrapper = ({ Component }) => {
  return (
    <UserDetailsProvider>
      <Component />
    </UserDetailsProvider>
  )
}

export const common_routes = [
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <CommonWrapper Component={CaseLayout} />,
    children: [
      {
        path: AppRoutingConfig.VIEW_PHYSICIAN_PROFILE,
        element: <lazy.ViewPhysicianProfile />
      },
      {
        path: AppRoutingConfig.VIEW_ACTIVITY_LOGS,
        element: <lazy.ViewActivityLogs />
      }
    ]
  },
  {
    path: AppRoutingConfig.DOCUMENT_UPLOAD,
    element: <lazy.DocumentUploader />
  },
  {
    path: AppRoutingConfig.DOCUMENT_SO_UPLOAD,
    element: <lazy.SODocumentUploader />
  },
  {
    path: AppRoutingConfig.CASE_PAYMENT_CONFIRMATION,
    element: <lazy.PaymentConfirmaiton />
  },
  {
    path: AppRoutingConfig.VIEW_PROFILE,
    element: <CommonWrapper Component={lazy.ViewProfile} />
  },
  {
    path: AppRoutingConfig.VIEW_COMMUNITY,
    element: <lazy.ViewCommunity />
  },
  {
    path: AppRoutingConfig.VIEW_CASE_REQUEST,
    element: <CommonWrapper Component={CaseLayout} />,
    children: [
      {
        path: AppRoutingConfig.VIEW_CASE_REQUEST_BY_ID,
        element: <lazy.ViewCase />
      }
    ]
  }
]
