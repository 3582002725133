import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get profession list
export const get_profession_list = createAsyncThunk(
  'medicalSettings/get_profession_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/profile/get-professions?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add profession
export const add_profession = createAsyncThunk(
  'medicalSettings/add_profession',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/add-profession`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update profession
export const update_profession = createAsyncThunk(
  'medicalSettings/update_profession',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/update-profession`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get profession select list
export const get_profession_select_list = createAsyncThunk(
  'medicalSettings/get_profession_select_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/profile/get-professions?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search || ''}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add speciality
export const add_speciality = createAsyncThunk(
  'medicalSettings/add_speciality',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/specialty/create`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update speciality
export const update_speciality = createAsyncThunk(
  'medicalSettings/update_speciality',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/specialty/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get specialities list
export const get_specialities_list = createAsyncThunk(
  'medicalSettings/get_specialities_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/specialty/get-all-grouped?page=${payload?.page}&pageSize=${payload?.pageSize}&search=${payload?.search}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get specialities list for sub specialities
export const get_specialities_list_for_subspecialities = createAsyncThunk(
  'medicalSettings/get_specialities_list_for_subspecialities',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/subspecialty/get-all-grouped?page=${payload?.page}&pageSize=${payload?.pageSize}&search=${payload?.search}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get sub specialities list
export const get_sub_specialities_list = createAsyncThunk(
  'medicalSettings/get_sub_specialities_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/subspecialty/get-all`, payload)
      if (response.status === 0) {
        return { response: response.data, payload }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add sub specialities
export const add_sub_specialities = createAsyncThunk(
  'medicalSettings/add_sub_specialities',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/subspecialty/create`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update sub specialities
export const update_sub_specialities = createAsyncThunk(
  'medicalSettings/update_sub_specialities',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/subspecialty/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get organs list
export const get_organs_list = createAsyncThunk(
  'medicalSettings/get_organs_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/organ/get-all?page=${payload?.page}&pageSize=${payload?.pageSize}&search=${payload?.search}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get organs select list
export const get_organs_select_list = createAsyncThunk(
  'medicalSettings/get_organs_select_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/organ/get-all?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search || ''}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add organs
export const add_organ = createAsyncThunk(
  'medicalSettings/add_organ',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/organ/create`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update organ
export const update_organ = createAsyncThunk(
  'medicalSettings/update_organ',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      console.log('add organ')
      const response = await axiosInstance.post(`v1/admin/organ/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get diagnosis list
export const get_diagnosis_list = createAsyncThunk(
  'medicalSettings/get_diagnosis_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/diagnosis/get-all?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search || ''}`
      )
      if (response.status === 0) {
        return { response: response.data, payload }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get diagnosis select list
export const get_diagnosis_select_list = createAsyncThunk(
  'medicalSettings/get_diagnosis_select_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/diagnosis/get-all?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search || ''}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add diagnosis
export const add_diagnosis = createAsyncThunk(
  'medicalSettings/add_diagnosis',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/diagnosis/create`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update diagnosis
export const update_diagnosis = createAsyncThunk(
  'medicalSettings/update_diagnosis',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/diagnosis/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get expertise list
export const get_expertise_list = createAsyncThunk(
  'medicalSettings/get_expertise_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/profile/get-expertise?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add expertise
export const add_expertise = createAsyncThunk(
  'medicalSettings/add_expertise',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/add-expertise`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update expertise
export const update_expertise = createAsyncThunk(
  'medicalSettings/update_expertise',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/update-expertise`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get universities list
export const get_universities_list = createAsyncThunk(
  'medicalSettings/get_universities_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/university/get-all?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add university
export const add_university = createAsyncThunk(
  'medicalSettings/add_university',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/university/create`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update university
export const update_university = createAsyncThunk(
  'medicalSettings/update_university',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/university/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get diploma list
export const get_diploma_list = createAsyncThunk(
  'medicalSettings/get_diploma_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/profile/get-diplomas?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add diploma
export const add_diploma = createAsyncThunk(
  'medicalSettings/add_diploma',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/add-diploma`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update diploma
export const update_diploma = createAsyncThunk(
  'medicalSettings/update_diploma',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/update-diploma`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get tags list
export const get_tags_list = createAsyncThunk(
  'medicalSettings/get_tags_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/te/tags/get-all?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add tag
export const add_tag = createAsyncThunk(
  'medicalSettings/add_tag',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/tags/create`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update tag
export const update_tag = createAsyncThunk(
  'medicalSettings/update_tag',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/tags/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get language list
export const get_language_list = createAsyncThunk(
  'medicalSettings/get_language_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/profile/get-languages?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.search}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add language
export const add_language = createAsyncThunk(
  'medicalSettings/add_language',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/add-language`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update language
export const update_language = createAsyncThunk(
  'medicalSettings/update_language',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/update-language`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
