// Second Opinion Reducer
import { createSlice } from '@reduxjs/toolkit'
import {
  add_request_info,
  create_so_case,
  fetch_request_info,
  get_community_profile_info_for_drafted_cases,
  get_diagnosis_list,
  get_drafted_details,
  get_drafted_so_cases,
  get_organ_list,
  get_payment_info,
  get_physician_profile_info_for_drafted_cases,
  make_payment
} from '../services/soService'
import { capitalizeFirstLetter } from 'utils/formUtil'

let initialState = {
  loading: false,
  profileData: [],
  communityData: [],
  networkData: [],
  patientIdentity: '',
  organList: {
    loading: false,
    data: []
  },
  diagnosisList: {
    loading: false,
    data: [],
    pagination: {}
  },
  selectedOrgan: {
    image: '',
    title: '',
    subText: '',
    value: ''
  },
  selectedDiagnosis: {
    image: '',
    title: '',
    subText: '',
    value: ''
  },
  newCase: {
    profileSelected: {},
    communitySelected: {},
    isProfileSelected: false,
    isCommunitySelected: false
  },
  patientInfo: {
    loading: false
  },
  requestInfo: {
    loading: false,
    normal_consultation: null,
    video_consultation: null,
    questionnaire: [],
    instructions: []
  },
  paymentInfo: {
    loading: false,
    hasNotPaymentMethod: false,
    fee: {},
    discount_code: '',
    paymentData: {}
  },
  soCaseId: null,
  draftedCaseList: {
    loading: false,
    data: []
  },
  draftedCaseDetails: {
    loading: false,
    data: {},
    caseId: null
  }
}

export const soCaseSlice = createSlice({
  name: 'soCase',
  initialState,
  reducers: {
    clearCaseData: (state) => {
      const preservePaymentInfo = state.paymentInfo
      return {
        ...initialState,
        paymentInfo: preservePaymentInfo
      }
    },

    clearPaymentData: (state) => {
      state.paymentInfo = initialState.paymentInfo
    },

    selectPatientIdentity: (state, action) => {
      state.patientIdentity = action.payload
    },

    selectOrgan: (state, action) => {
      state.selectedOrgan = action.payload
    },
    selectDiagnosis: (state, action) => {
      state.selectedDiagnosis = action.payload
    },
    selectProfile: (state, action) => {
      state.newCase.profileSelected = action.payload
      state.newCase.isProfileSelected = true
      state.newCase.isCommunitySelected = false
      state.newCase.communitySelected = {}
    },
    selectCommnuinty: (state, action) => {
      state.newCase.communitySelected = action.payload
      state.newCase.isProfileSelected = false
      state.newCase.isCommunitySelected = true
      state.newCase.profileSelected = {}
    },
    favouritePhysicianCard: (state, action) => {
      let physicianCard = state.getExpertPhysicianList.expertPhysicianData?.find(
        (cardData) => cardData.id === action.payload
      )
      physicianCard.isFavourite = !physicianCard.isFavourite
    },
    setPatientData: (state, action) => {
      state.newCase.patientInfo = action.payload
    },
    closeHasNoPaymentMethod: (state) => {
      state.paymentInfo.hasNotPaymentMethod = false
    }
  },
  extraReducers: (builder) => {
    // Get Organ List
    builder.addCase(get_organ_list.pending, (state) => {
      state.organList.loading = true
    })
    builder.addCase(get_organ_list.fulfilled, (state, action) => {
      state.organList.data = action.payload?.response?.map((item) => ({
        image: item.icon,
        title: item.display_name,
        value: item.value
      }))
      state.organList.loading = false
    })
    builder.addCase(get_organ_list.rejected, (state) => {
      state.organList.loading = false
    })

    // Get Diagnosis List
    builder.addCase(get_diagnosis_list.pending, (state) => {
      state.diagnosisList.loading = true
    })
    builder.addCase(get_diagnosis_list.fulfilled, (state, action) => {
      state.diagnosisList.data = action.payload?.response?.data?.map((item) => ({
        image: item?.icon,
        title: item?.display_name,
        value: item?.value
      }))

      state.diagnosisList.pagination = action.payload?.response?.pagination
      state.diagnosisList.loading = false
    })
    builder.addCase(get_diagnosis_list.rejected, (state) => {
      state.diagnosisList.loading = false
    })

    // Create SO case
    builder.addCase(create_so_case.pending, (state) => {
      state.patientInfo.loading = true
    })
    builder.addCase(create_so_case.fulfilled, (state, action) => {
      state.patientInfo.loading = false
      state.soCaseId = action?.payload?.response?.case_id
    })
    builder.addCase(create_so_case.rejected, (state) => {
      state.patientInfo.loading = false
    })

    // Get Request Info based on patient info

    builder.addCase(fetch_request_info.pending, (state) => {
      state.requestInfo.loading = true
    })
    builder.addCase(fetch_request_info.fulfilled, (state, action) => {
      state.requestInfo = {
        loading: false,
        ...action?.payload?.response
      }
    })
    builder.addCase(fetch_request_info.rejected, (state) => {
      state.requestInfo.loading = false
    })

    // Set Request Info

    builder.addCase(add_request_info.pending, (state) => {
      state.requestInfo.loading = true
    })
    // builder.addCase(add_request_info.fulfilled, (state, action) => {
    //   state.requestInfo = {...state.requestInfo,
    //     loading: false,
    //   }
    // })
    builder.addCase(add_request_info.rejected, (state) => {
      state.requestInfo.loading = false
    })

    // Getting payment information
    builder.addCase(get_payment_info.pending, (state) => {
      state.requestInfo.loading = true
    })

    builder.addCase(get_payment_info.fulfilled, (state, action) => {
      state.requestInfo.loading = false
      state.paymentInfo.loading = false
      let errorArray = Array.isArray(action?.payload?.response)
      if (errorArray) {
        state.paymentInfo.hasNotPaymentMethod = true
      } else {
        state.paymentInfo.fee = action?.payload?.response
      }
    })

    builder.addCase(get_payment_info.rejected, (state) => {
      state.requestInfo.loading = false
      state.paymentInfo.loading = false
    })

    // Make payment

    builder.addCase(make_payment.pending, (state) => {
      state.paymentInfo.loading = true
    })
    builder.addCase(make_payment.fulfilled, (state, action) => {
      state.paymentInfo.loading = false
      state.paymentInfo.paymentData = action?.payload?.response
    })
    builder.addCase(make_payment.rejected, (state) => {
      state.paymentInfo.loading = false
    })

    // Get drafted cases

    builder.addCase(get_drafted_so_cases.pending, (state) => {
      state.draftedCaseList.loading = true
    })
    builder.addCase(get_drafted_so_cases.fulfilled, (state, action) => {
      state.draftedCaseList.loading = false
      state.draftedCaseList.data = action?.payload?.response
    })
    builder.addCase(get_drafted_so_cases.rejected, (state) => {
      state.draftedCaseList.loading = false
    })

    // Get drafted case details
    builder.addCase(get_drafted_details.pending, (state) => {
      state.draftedCaseDetails.loading = true
    })
    builder.addCase(get_drafted_details.fulfilled, (state, action) => {
      state.draftedCaseDetails.data = action?.payload?.response
      state.draftedCaseDetails.caseId = action?.payload?.response?.id
      state.patientIdentity = action?.payload?.response?.patient_identity
      state.selectedOrgan = {
        title: action?.payload?.response?.organ?.display_name,
        value: action?.payload?.response?.organ?.value,
        speciality: action?.payload?.response?.organ?.specialty_value
      }
      state.selectedDiagnosis = {
        title: action?.payload?.response?.diagnosis?.display_name,
        value: action?.payload?.response?.diagnosis?.value
      }
    })

    // Get physician details
    builder.addCase(get_physician_profile_info_for_drafted_cases.fulfilled, (state, action) => {
      const item = action.payload?.response // single object
      const {
        bio,
        has_favorite,
        languages,
        physician_level,
        profession,
        specialty,
        specialty_slug
      } = item

      const updatedExpertPhysicianData = {
        id: item?.id,
        userNo: item?.user_no,
        isLoading: false,
        isVerified: true,
        profileImage: `https://api.dicebear.com/7.x/miniavs/svg?seed=${item?.id}`,
        profileName: `${item?.title} ${item?.first_name} ${item?.last_name}`,
        isFavourite: has_favorite ? true : false,
        onHoliday: false,
        wasActive:
          item?.account_setting?.status === 'available' && item?.account_setting?.replies_within
            ? true
            : false,
        repliesWithIn: item?.account_setting?.replies_within,
        isExpert: physician_level && physician_level !== 'None' ? true : false,
        physicianLevelTag: physician_level,
        department: profession,
        speciality: specialty,
        speciality_slug: specialty_slug,
        address:
          item?.address?.city && item?.address?.country
            ? `${item?.address?.city}, ${item?.address?.country}`
            : undefined,
        institute: item?.place_of_works?.[0]?.name,
        description: bio,
        freeAssitance: item?.request_config?.is_free_asst_req ? true : false,
        teleExpertiseFree: item?.physician_hcp_billing_info?.is_te_free ? false : true,
        caseRate: item?.physician_hcp_billing_info?.fee_for_te,
        acceptsSSN: false,
        ratings: 4.9, // placeholder, you might need to update this dynamically
        totalRatings: 64, // placeholder, you might need to update this dynamically
        languages: languages,
        teleExpertiseFee: item?.physician_hcp_billing_info?.is_te_free
          ? 'Free'
          : item?.physician_hcp_billing_info?.fee_for_te,
        secondOpinionWithVideo: item?.physician_hcp_billing_info?.is_so_wv_free
          ? 'Free'
          : item?.physician_hcp_billing_info?.fee_for_so_wv,
        secondOpinionWithOutVideo: item?.physician_hcp_billing_info?.is_so_wov_free
          ? 'Free'
          : item?.physician_hcp_billing_info?.fee_for_so_wov,
        identityNo: item?.user_no,
        rppsNumber: item?.rpps_number,
        phone: item?.phone,
        email: item?.email
      }

      state.newCase.profileSelected = updatedExpertPhysicianData
      state.newCase.isProfileSelected = true
      state.newCase.isCommunitySelected = false
      state.newCase.communitySelected = {}
      state.draftedCaseDetails.loading = false
    })
    builder.addCase(get_physician_profile_info_for_drafted_cases.rejected, (state) => {
      state.draftedCaseDetails.loading = false
    })

    // Get Community

    // Get physician details
    builder.addCase(get_community_profile_info_for_drafted_cases.fulfilled, (state, action) => {
      const item = action.payload?.response // single object

      const capitalizedCommunityTypeLetter = item?.community_type
        .split('_')
        .map((element) => capitalizeFirstLetter(element))
        .join(' ')
      const specialityArray = item?.comm_specialties?.map((item) => item?.specialty_type?.dn)

      const updatedExpertPhysicianData = {
        id: item?.id,
        communityId: item?.community_id,
        communityImage: `https://api.dicebear.com/7.x/miniavs/svg?seed=${1}`,
        communityName: item?.community_title,
        isLoading: false,
        isVerified: true,
        onHoliday: false,
        wasActive: true,
        repliesWithIn: item?.comm_request_config?.replies_within,
        description: item?.introduction,
        ratings: 4.9,
        totalRatings: 64,
        communitySpeciality: specialityArray,
        communityType: capitalizedCommunityTypeLetter,
        communityMemberCount: item?.users_count,
        address:
          item?.comm_addresses?.[0]?.address?.city && item?.comm_addresses?.[0]?.address?.country
            ? `${item.comm_addresses[0].address.city}, ${item.comm_addresses[0].address.country}`
            : undefined,
        acceptsSSN: item?.comm_request_config?.is_ss_req,
        isTeleExpertise:
          item?.comm_request_config?.is_te_req && !item?.comm_billing_info?.is_te_free
            ? true
            : false,
        teleExpertiseRate: item?.comm_billing_info?.fee_for_te,
        isSecondOpinionWov:
          item?.comm_request_config?.is_so_req && !item?.comm_billing_info?.is_so_wov_free
            ? true
            : false,
        secondOpinionWovRate: item?.comm_billing_info?.fee_for_so_wov,
        isSecondOpinionWv:
          item?.comm_request_config?.is_so_req && !item?.comm_billing_info?.is_so_wv_free
            ? true
            : false,
        secondOpinionWvRate: item?.comm_billing_info?.fee_for_so_wv,
        yourCommunity: item?.is_your_community,
        isFavourite: item?.has_favorite ? true : false,
        email: item?.email,
        phone: item?.phone,
        website: item?.website_url,
        languages: item?.language_speaken
      }

      state.newCase.profileSelected = {}
      state.newCase.isProfileSelected = false
      state.newCase.isCommunitySelected = true
      state.newCase.communitySelected = updatedExpertPhysicianData
      state.draftedCaseDetails.loading = false
    })
    builder.addCase(get_community_profile_info_for_drafted_cases.rejected, (state) => {
      state.draftedCaseDetails.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  selectPatientIdentity,
  selectOrgan,
  selectDiagnosis,
  selectProfile,
  setPatientData,
  closeHasNoPaymentMethod,
  clearCaseData,
  clearPaymentData,
  favouritePhysicianCard,
  selectCommnuinty
} = soCaseSlice.actions

export default soCaseSlice.reducer
