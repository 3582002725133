import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get manage discount list
export const get_manage_discount_list = createAsyncThunk(
  'rolesAndPermission/get_manage_discount_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/coupon-code/get`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// create coupon code
export const create_coupon_code = createAsyncThunk(
  'rolesAndPermission/create_coupon_code',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/coupon-code`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update coupon code
export const update_coupon_code = createAsyncThunk(
  'rolesAndPermission/update_coupon_code',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/coupon-code/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update coupon code
export const verify_coupon_code = createAsyncThunk(
  'rolesAndPermission/verify_coupon_code',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/coupon-code/verify`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// subscribe plan
export const subscribe_plan = createAsyncThunk(
  'rolesAndPermission/subscribe_plan',
  async (
    { payload, showNotification, handleOpenPlanSuccessfullyUpgradedDialog },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/payment/subscribe-plan`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleOpenPlanSuccessfullyUpgradedDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get user level activity
export const get_user_level_activity = createAsyncThunk(
  'rolesAndPermission/get_user_level_activity',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/user-level-activity/get`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get activity
export const get_activity = createAsyncThunk(
  'rolesAndPermission/get_activity',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/user-level-activity/get-activity`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update user level activities
export const update_user_level_activities = createAsyncThunk(
  'rolesAndPermission/update_user_level_activities',
  async ({ payload, showNotification, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/user-level-activity/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleGetData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// delete coupon code
export const delete_coupon_code = createAsyncThunk(
  'rolesAndPermission/delete_coupon_code',
  async (
    { payload, showNotification, handleGetCouponData, searchValue, handleCloseDeactivateDialog },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/coupon-code/delete`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDeactivateDialog()
        handleGetCouponData(searchValue)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get subscription plan
export const get_subscription_plan = createAsyncThunk(
  'rolesAndPermission/get_subscription_plan',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/subscription_plan/get`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// create subscription plan
export const create_subscription_plan = createAsyncThunk(
  'rolesAndPermission/create_subscription_plan',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/subscription_plan`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update subscription plan
export const update_subscription_plan = createAsyncThunk(
  'rolesAndPermission/update_subscription_plan',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/subscription_plan/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
