import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// create community step one
export const create_community_step_one = createAsyncThunk(
  'community/create_community_step_one',
  async ({ payload, showNotification, dispatch, navigateToNextForm }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/community/validate-st1`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        dispatch(navigateToNextForm(1))
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// create community step two
export const create_community_step_two = createAsyncThunk(
  'community/create_community_step_two',
  async ({ payload, showNotification, dispatch, navigateToNextForm }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/community/validate-st2`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        dispatch(navigateToNextForm(2))
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// create community step three
export const create_community_step_three = createAsyncThunk(
  'community/create_community_step_three',
  async ({ payload, showNotification, dispatch, navigateToNextForm }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/community/validate-st3`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        dispatch(navigateToNextForm(3))
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// create community
export const create_community = createAsyncThunk(
  'community/create_community',
  async (
    { payload, showNotification, dispatch, navigate, clearCommunityFormData, navigateToNextTab },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/admin/create-community`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        dispatch(clearCommunityFormData())
        dispatch(navigateToNextTab(2))
        navigate(AppRoutingConfig.ADD_COMMUNITY_MEMBER_CONFIRMATION)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get editor list
export const get_editor_list = createAsyncThunk(
  'community/get_editor_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/community/list-author-editor`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get author list
export const get_author_list = createAsyncThunk(
  'community/get_author_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/community/list-author-editor`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community list
export const get_community_list = createAsyncThunk(
  'community/get_community_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/list-community`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
