import { useTourComponentRefs } from 'context/TourRefsProvider'
import TourTitle from './tour-title'
import { Tour } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { closeTourModel } from '../../../redux/reducer/appReducer'
import './SynappTour.scss'

const SynappTour = () => {
  const { ref1, ref2, ref3, ref4, ref5, ref6 } = useTourComponentRefs()
  const open = useSelector((state) => state.app.tourOpen)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const steps = [
    {
      title: <TourTitle title={t('productTour.welcome.title')} />,
      description: t('productTour.welcome.description'),
      target: () => ref1.current
    },
    {
      title: <TourTitle title={t('productTour.dashboard.title')} />,
      description: t('productTour.dashboard.description'),
      placement: 'right',
      target: () => ref2.current
    },
    {
      title: <TourTitle title={t('productTour.myRequest.title')} />,
      description: t('productTour.myRequest.description'),
      placement: 'right',
      target: () => ref3.current
    },
    {
      title: <TourTitle title={t('productTour.myCommunity.title')} />,
      description: t('productTour.myCommunity.description'),
      placement: 'right',
      target: () => ref4.current
    },
    {
      title: <TourTitle title={t('productTour.myLibrary.title')} />,
      description: t('productTour.myLibrary.description'),
      placement: 'right',
      target: () => ref5.current
    },
    {
      title: <TourTitle title={t('productTour.settings.title')} />,
      description: t('productTour.settings.description'),
      placement: 'right',
      target: () => ref6.current
    }
  ]

  const customIndicatorsRender = (current, total) => (
    <span>
      {current + 1} of {total}
    </span>
  )

  return (
    <div className="synapp-tour-container">
      <Tour
        disabledInteraction
        open={open}
        onClose={() => dispatch(closeTourModel())}
        steps={steps}
        rootClassName="synapp-product-container"
        indicatorsRender={customIndicatorsRender}
      />
    </div>
  )
}

export default SynappTour
