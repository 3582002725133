import { createSlice } from '@reduxjs/toolkit'
import {
  get_favourite_physician_list,
  get_community_card_list,
  get_community_favourite_card_list,
  get_community_profile_info
} from '../services/directoryService'
import { capitalizeFirstLetter } from 'utils/formUtil'

export const directorySlice = createSlice({
  name: 'directory',
  initialState: {
    getFavouritePhysicianList: {
      loading: false,
      favouritePhysicianData: [],
      pagination: {}
    },
    getCommunityCardList: {
      getCommunityCard: null,
      loading: false
    },
    getFavouriteCommunityCardList: {
      getFavouriteCommunityCard: null,
      loading: false
    },
    getCommunityProfileData: {
      loading: false,
      communityProfileData: {}
    }
  },
  reducers: {
    unMarkPhysicianCard: (state, action) => {
      state.getFavouritePhysicianList.favouritePhysicianData =
        state.getFavouritePhysicianList.favouritePhysicianData?.filter(
          (cardData) => cardData.id !== action.payload
        )
    },
    favouriteCommunityCard: (state, action) => {
      let communityCard = state.getCommunityCardList.getCommunityCard.data?.find(
        (cardData) => cardData.id === action.payload
      )
      communityCard.isFavourite = !communityCard.isFavourite
    },
    unMarkCommunityCard: (state, action) => {
      state.getFavouriteCommunityCardList.getFavouriteCommunityCard.data =
        state.getFavouriteCommunityCardList.getFavouriteCommunityCard.data?.filter(
          (cardData) => cardData.id !== action.payload
        )
    }
  },
  extraReducers: (builder) => {
    // Get Favourite Physician
    builder.addCase(get_favourite_physician_list.pending, (state) => {
      state.getFavouritePhysicianList.loading = true
    })
    builder.addCase(get_favourite_physician_list.fulfilled, (state, action) => {
      const updatedExpertPhysicianData = action.payload?.response?.data?.map((item, index) => {
        const { auth_user } = item
        const { account_setting, physician_hcp_info, physician_hcp_billing_info } = auth_user
        return {
          id: auth_user?.id,
          userNo: auth_user?.user_no,
          isLoading: false,
          isVerified: true,
          profileImage: `https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`,
          profileName: `${auth_user?.title} ${auth_user?.first_name} ${auth_user?.last_name}`,
          isFavourite: true,
          onHoliday: false,
          wasActive:
            account_setting?.status === 'available' && account_setting?.replies_within
              ? true
              : false,
          repliesWithIn: account_setting?.replies_within,
          isExpert:
            physician_hcp_info?.physician_level && physician_hcp_info?.physician_level !== 'None'
              ? true
              : false,
          physicianLevelTag: physician_hcp_info?.physician_level,
          department: physician_hcp_info?.profession_type?.display_name,
          speciality: physician_hcp_info?.specialty_type?.display_name,
          speciality_slug: physician_hcp_info?.specialty,
          address:
            auth_user?.address?.city && auth_user?.address?.country
              ? `${auth_user?.address?.city}, ${auth_user?.address?.country}`
              : undefined,
          institute: auth_user?.place_of_works?.[0]?.name,
          description: physician_hcp_info?.bio,
          freeAssitance: auth_user?.request_config?.is_free_asst_req ? true : false,
          teleExpertiseFree: physician_hcp_billing_info?.is_te_free ? false : true,
          caseRate: physician_hcp_billing_info?.fee_for_te,
          acceptsSSN: false,
          ratings: 4.9,
          totalRatings: 64,
          languages: physician_hcp_billing_info?.languages,
          teleExpertiseFee: physician_hcp_billing_info?.is_te_free
            ? 'Free'
            : physician_hcp_billing_info?.fee_for_te,
          secondOpinionWithVideo: physician_hcp_billing_info?.is_so_wv_free
            ? 'Free'
            : physician_hcp_billing_info?.fee_for_so_wv,
          secondOpinionWithOutVideo: physician_hcp_billing_info?.is_so_wov_free
            ? 'Free'
            : physician_hcp_billing_info?.fee_for_so_wov,
          identityNo: auth_user?.user_no,
          rppsNumber: auth_user?.rpps_number,
          phone: auth_user?.phone,
          email: auth_user?.email
        }
      })
      state.getFavouritePhysicianList.favouritePhysicianData = updatedExpertPhysicianData
      state.getFavouritePhysicianList.pagination = action.payload?.response?.pagination
      state.getFavouritePhysicianList.loading = false
    })
    builder.addCase(get_favourite_physician_list.rejected, (state) => {
      state.getFavouritePhysicianList.loading = false
    })

    // Get Community Card List
    builder.addCase(get_community_card_list.pending, (state) => {
      state.getCommunityCardList.loading = true
    })
    builder.addCase(get_community_card_list.fulfilled, (state, action) => {
      const updatedCommunityCardData = action.payload?.response?.data?.map((item, index) => {
        const capitalizedCommunityTypeLetter = item?.community_type
          .split('_')
          .map((element) => capitalizeFirstLetter(element))
          .join(' ')
        const specialityArray = item?.comm_specialties?.map((item) => item?.specialty_type?.dn)
        return {
          id: item?.id,
          communityId: item?.community_id,
          communityImage: `https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`,
          communityName: item?.community_title,
          isLoading: false,
          isVerified: true,
          onHoliday: false,
          wasActive: true,
          repliesWithIn: item?.comm_request_config?.replies_within,
          description: item?.introduction,
          ratings: 4.9,
          totalRatings: 64,
          communitySpeciality: specialityArray,
          communityType: capitalizedCommunityTypeLetter,
          communityMemberCount: item?.users_count,
          address:
            item?.comm_addresses?.[0]?.address?.city && item?.comm_addresses?.[0]?.address?.country
              ? `${item.comm_addresses[0].address.city}, ${item.comm_addresses[0].address.country}`
              : undefined,
          acceptsSSN: item?.comm_request_config?.is_ss_req,
          isTeleExpertise:
            item?.comm_request_config?.is_te_req && !item?.comm_billing_info?.is_te_free
              ? true
              : false,
          teleExpertiseRate: item?.comm_billing_info?.fee_for_te,
          isSecondOpinionWov:
            item?.comm_request_config?.is_so_req && !item?.comm_billing_info?.is_so_wov_free
              ? true
              : false,
          secondOpinionWovRate: item?.comm_billing_info?.fee_for_so_wov,
          isSecondOpinionWv:
            item?.comm_request_config?.is_so_req && !item?.comm_billing_info?.is_so_wv_free
              ? true
              : false,
          secondOpinionWvRate: item?.comm_billing_info?.fee_for_so_wv,
          yourCommunity: item?.is_your_community,
          isFavourite: item?.has_favorite ? true : false,
          email: item?.email,
          phone: item?.phone,
          website: item?.website_url,
          languages: item?.language_speaken
        }
      })
      state.getCommunityCardList.loading = false
      state.getCommunityCardList.getCommunityCard = {
        data: updatedCommunityCardData,
        pagination: action.payload?.response?.pagination
      }
    })
    builder.addCase(get_community_card_list.rejected, (state) => {
      state.getCommunityCardList.loading = false
    })

    // Get Community Favourite Card List
    builder.addCase(get_community_favourite_card_list.pending, (state) => {
      state.getFavouriteCommunityCardList.loading = true
    })
    builder.addCase(get_community_favourite_card_list.fulfilled, (state, action) => {
      const updatedFavouriteCommunityCardData = action.payload?.response?.data?.map(
        (item, index) => {
          const { community } = item
          const capitalizedCommunityTypeLetter = community?.community_type
            .split('_')
            .map((element) => capitalizeFirstLetter(element))
            .join(' ')
          const specialityArray = community?.comm_specialties?.map(
            (item) => item?.specialty_type?.dn
          )
          return {
            id: community?.id,
            communityId: community?.community_id,
            communityImage: `https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`,
            communityName: community?.community_title,
            isLoading: false,
            isVerified: true,
            onHoliday: false,
            wasActive: true,
            repliesWithIn: community?.comm_request_config?.replies_within,
            description: community?.introduction,
            ratings: 4.9,
            totalRatings: 64,
            communitySpeciality: specialityArray,
            communityType: capitalizedCommunityTypeLetter,
            communityMemberCount: community?.users_count,
            address:
              community?.comm_addresses?.[0]?.address?.city &&
              community?.comm_addresses?.[0]?.address?.country
                ? `${community.comm_addresses[0].address.city}, ${community.comm_addresses[0].address.country}`
                : undefined,
            acceptsSSN: community?.comm_request_config?.is_ss_req,
            isTeleExpertise:
              community?.comm_request_config?.is_te_req && !community?.comm_billing_info?.is_te_free
                ? true
                : false,
            teleExpertiseRate: community?.comm_billing_info?.fee_for_te,
            isSecondOpinionWov:
              community?.comm_request_config?.is_so_req &&
              !community?.comm_billing_info?.is_so_wov_free
                ? true
                : false,
            secondOpinionWovRate: community?.comm_billing_info?.fee_for_so_wov,
            isSecondOpinionWv:
              community?.comm_request_config?.is_so_req &&
              !community?.comm_billing_info?.is_so_wv_free
                ? true
                : false,
            secondOpinionWvRate: community?.comm_billing_info?.fee_for_so_wv,
            yourCommunity: community?.is_your_community,
            isFavourite: true
          }
        }
      )
      state.getFavouriteCommunityCardList.loading = false
      state.getFavouriteCommunityCardList.getFavouriteCommunityCard = {
        data: updatedFavouriteCommunityCardData,
        pagination: action.payload?.response?.pagination
      }
    })
    builder.addCase(get_community_favourite_card_list.rejected, (state) => {
      state.getFavouriteCommunityCardList.loading = false
    })

    // Get Community Profile Info
    builder.addCase(get_community_profile_info.pending, (state) => {
      state.getCommunityProfileData.loading = true
    })
    builder.addCase(get_community_profile_info.fulfilled, (state, action) => {
      let updatedCommunityProfileData = {}
      if (action?.payload?.response && Object.keys(action?.payload?.response)?.length > 0) {
        const {
          id,
          community_id,
          comm_request_config,
          community_title,
          community_type,
          introduction,
          comm_addresses,
          phone,
          email,
          website_url,
          language_speaken,
          comm_expertis,
          comm_specialties,
          comm_subspecialties,
          community_members,
          universities,
          publications,
          board_certifications
        } = action.payload.response
        const capitalizedCommunityTypeLetter = community_type
          .split('_')
          .map((element) => capitalizeFirstLetter(element))
          .join(' ')
        updatedCommunityProfileData = {
          id,
          communityId: community_id,
          onHoliday: false,
          wasActive: true,
          repliesWithIn: comm_request_config?.replies_within,
          communityImage: `https://api.dicebear.com/7.x/miniavs/svg?seed=${id}`,
          communityName: community_title,
          isVerified: true,
          communityType: capitalizedCommunityTypeLetter,
          description: introduction,
          address:
            comm_addresses?.[0]?.address?.city && comm_addresses?.[0]?.address?.country
              ? `${comm_addresses[0].address.city}, ${comm_addresses[0].address.country}`
              : undefined,
          phone,
          email,
          website: website_url,
          languages: language_speaken,
          expertise: comm_expertis?.map((item) => item?.expertise?.display_name),
          specialities: comm_specialties?.map((item) => item?.specialty_type?.display_name),
          subSpecialities: comm_subspecialties?.map((item) => item?.subspecialty?.display_name),
          communityMembers: community_members?.map((item, index) => {
            return {
              member_image: `https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`,
              member_name: item?.auth_user?.member_name,
              department: item?.auth_user?.physician_hcp_info?.specialty_type?.d
            }
          }),
          universities,
          certifications: board_certifications,
          publications: publications?.map((item) => {
            return {
              url: item?.publication_link,
              label: item?.title
            }
          })
        }
      }
      state.getCommunityProfileData.communityProfileData = updatedCommunityProfileData
      state.getCommunityProfileData.loading = false
    })
    builder.addCase(get_community_profile_info.rejected, (state) => {
      state.getCommunityProfileData.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { unMarkPhysicianCard, favouriteCommunityCard, unMarkCommunityCard } =
  directorySlice.actions

export default directorySlice.reducer
