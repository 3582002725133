import { Card, Flex } from 'antd'
import Box from 'components/common/box'
import Navbar from 'components/common/navbar'
import { Outlet } from 'react-router-dom'
import SettingsSidebar from 'components/common/settings-sidebar'
import { useState } from 'react'
import { MenuFoldOutlined } from '@ant-design/icons'
import { physicianSettingsSidebarUtils } from 'utils/sidebarUtils/physicianSettingsSidebarUtils'
import { useTranslation } from 'react-i18next'
import { synappAdminSettingsSidebarUtils } from 'utils/sidebarUtils/synappAdminSettingsSidebarUtils'
import { roles } from '../../../constants'
import { useUserDetails } from 'context/UserDetailsProvider'
import { patientSettingsSidebarUtils } from 'utils/sidebarUtils/patientSettingsSidebarUtils'
import './PrimaryLayout.scss'
import { authorEditorSettingsSidebarUtils } from 'utils/sidebarUtils/authorEditorSettingsSidebarUtils'

const PrimaryLayout = () => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* sidebar collapsed dependencies */
  const [collapsed, setCollapsed] = useState(false)
  /* sidebar collapsed dependencies */

  /* sidebar dependencies */
  const renderRoleBasedSidebarUtil = {
    [roles.superAdmin]: synappAdminSettingsSidebarUtils(t),
    [roles.physician]: physicianSettingsSidebarUtils(t),
    [roles.editor]: authorEditorSettingsSidebarUtils(t),
    [roles.author]: authorEditorSettingsSidebarUtils(t),
    [roles.patient]: patientSettingsSidebarUtils(t)
  }
  const { selectedRole } = useUserDetails()
  const menuGroup = renderRoleBasedSidebarUtil[selectedRole]
  /* sidebar dependencies */

  return (
    <div className="primary-layout-wrapper">
      <div
        onClick={() => setCollapsed(!collapsed)}
        className={`settings-sidebar-button ${collapsed ? 'settings-trigger-collapsed-zero' : 'settings-trigger-collapsed-nonzero'} ${collapsed ? 'settings-toggle-left' : 'settings-toggle-right'}`}
      >
        <MenuFoldOutlined />
      </div>
      <Navbar isPrimaryLayout setSidebarCollapsed={setCollapsed} />
      <Box style={{ minHeight: '100dvh' }}>
        <Flex gap="1rem">
          <SettingsSidebar
            menuGroup={menuGroup}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <Card className="settings-content-wrapper">
            <Outlet />
          </Card>
        </Flex>
      </Box>
    </div>
  )
}

export default PrimaryLayout
