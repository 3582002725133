import { createSlice } from '@reduxjs/toolkit'
import {
  get_my_so_bookmarked_requests,
  get_my_telexpertise_bookmarked_requests,
  get_my_telexpertise_draft_cases,
  view_telexpertise_draft_cases
} from '../services/myLibraryService'

export const myLibrarySlice = createSlice({
  name: 'myLibrary',
  initialState: {
    loading: false,
    draftCasesList: {
      loading: false,
      data: []
    },
    viewDraftCases: {
      loading: false,
      data: {}
    },
    bookmarkedRequestsList: {
      loading: false,
      data: []
    }
  },
  reducers: {
    removeViewCaseDetails: (state) => {
      state.viewDraftCases.data = {}
    }
  },
  extraReducers: (builder) => {
    // Draft cases list
    builder.addCase(get_my_telexpertise_draft_cases.pending, (state) => {
      state.draftCasesList.loading = true
    })
    builder.addCase(get_my_telexpertise_draft_cases.fulfilled, (state, action) => {
      state.draftCasesList.loading = false
      state.draftCasesList.data = action.payload?.response
    })
    builder.addCase(get_my_telexpertise_draft_cases.rejected, (state) => {
      state.draftCasesList.loading = false
    })

    // View Draft Cases
    builder.addCase(view_telexpertise_draft_cases.pending, (state) => {
      state.viewDraftCases.loading = true
    })
    builder.addCase(view_telexpertise_draft_cases.fulfilled, (state, action) => {
      state.viewDraftCases.loading = false
      state.viewDraftCases.data = action.payload?.response
    })
    builder.addCase(view_telexpertise_draft_cases.rejected, (state) => {
      state.viewDraftCases.loading = false
    })

    // Bookmarked requests list
    builder.addCase(get_my_telexpertise_bookmarked_requests.pending, (state) => {
      state.bookmarkedRequestsList.loading = true
    })
    builder.addCase(get_my_telexpertise_bookmarked_requests.fulfilled, (state, action) => {
      state.bookmarkedRequestsList.loading = false
      state.bookmarkedRequestsList.data = action.payload?.response
    })
    builder.addCase(get_my_telexpertise_bookmarked_requests.rejected, (state) => {
      state.bookmarkedRequestsList.loading = false
    })

    // Bookmarked requests list
    builder.addCase(get_my_so_bookmarked_requests.pending, (state) => {
      state.bookmarkedRequestsList.loading = true
    })
    builder.addCase(get_my_so_bookmarked_requests.fulfilled, (state, action) => {
      state.bookmarkedRequestsList.loading = false
      state.bookmarkedRequestsList.data = action.payload?.response
    })
    builder.addCase(get_my_so_bookmarked_requests.rejected, (state) => {
      state.bookmarkedRequestsList.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { removeViewCaseDetails } = myLibrarySlice.actions

export default myLibrarySlice.reducer
