export const listData = [
  {
    heading: 'Edward Curr',
    notificationText: 'created a new case Case ID#12345',
    notificationDescription:
      'This is a great idea! I will steal it, say it’s mine, and give credit to myself of course... wait... I already did!',
    notificationTime: '17 min ago'
  },
  {
    heading: 'Edward Curr',
    notificationText: 'created a new case Case ID#12345',
    notificationTime: '17 min ago'
  },
  {
    heading: 'Edward Curr',
    notificationText: 'created a new case Case ID#12345',
    notificationDescription:
      'This is a great idea! I will steal it, say it’s mine, and give credit to myself of course... wait... I already did!',
    notificationTime: '17 min ago'
  },
  {
    heading: 'Edward Curr',
    notificationText: 'created a new case Case ID#12345',
    notificationTime: '17 min ago'
  }
]
