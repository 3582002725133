import { createSlice } from '@reduxjs/toolkit'
import {
  get_professional_info,
  update_professional_info
} from '../services/professionalInfoService'

export const professionalInfoSlice = createSlice({
  name: 'professionalInfo',
  initialState: {
    professionalInfo: {
      loading: false,
      user_professional_info: {}
    },
    updateProfessionalInfo: {
      loading: false
    }
  },
  extraReducers: (builder) => {
    // Get Professional Info
    builder.addCase(get_professional_info.pending, (state) => {
      state.professionalInfo.loading = true
    })
    builder.addCase(get_professional_info.fulfilled, (state, action) => {
      state.professionalInfo.loading = false
      state.professionalInfo.user_professional_info = action.payload?.response
    })
    builder.addCase(get_professional_info.rejected, (state) => {
      state.professionalInfo.loading = false
    })

    // Update Professional Info
    builder.addCase(update_professional_info.pending, (state) => {
      state.updateProfessionalInfo.loading = true
    })

    builder.addCase(update_professional_info.fulfilled, (state) => {
      state.updateProfessionalInfo.loading = false
    })

    builder.addCase(update_professional_info.rejected, (state) => {
      state.updateProfessionalInfo.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = professionalInfoSlice.actions

export default professionalInfoSlice.reducer
