import { createSlice } from '@reduxjs/toolkit'
import {
  get_community_dashboard,
  get_physician_dashboard
} from '../../redux/services/dashboardService'
import { translate } from 'i18n/i18n'

const initialState = {
  physicianDashboard: {
    loading: false,
    data: [
      {
        id: 1,
        title: translate('Dashboard.TelexpertiseRevenue'),
        amount: 0.0,
        currency: '€'
      },
      {
        id: 2,
        title: translate('Dashboard.MeanTimeToAnswerRequest'),
        value: '0 hours',
        percent: 0,
        isIncreased: true,
        description: translate('Dashboard.FasterSinceLastMonth')
      },
      {
        id: 3,
        title: translate('Dashboard.RequestsAnswered'),
        value: 0
      }
    ]
  },
  communityDashboard: {
    loading: false,
    data: [
      {
        id: 1,
        title: translate('Home.TelexpertiseRequestsReceived'),
        count: 23
      },
      {
        id: 2,
        title: translate('Home.SecondOpinionRequestsReceived'),
        count: 5
      },
      {
        id: 3,
        title: translate('Home.AllMembers'),
        count: 12
      }
    ]
  }
}
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get physician dashboard
    builder.addCase(get_physician_dashboard.pending, (state) => {
      state.physicianDashboard.loading = true
    })
    builder.addCase(get_physician_dashboard.fulfilled, (state, action) => {
      const response = action.payload?.response
      if (!response) return

      const { sum, request_count, last_answer_in_min, answer_in_min } = response
      let default_data = state.physicianDashboard.data

      const getCard = (data, id) => data?.find((element) => element.id === id)

      const revenueCard = getCard(default_data, 1)
      const timeToAnswerRequestCard = getCard(default_data, 2)
      const requestsAnsweredCard = getCard(default_data, 3)

      if (revenueCard) revenueCard.amount = sum?.toFixed(2) || '0.00'
      if (requestsAnsweredCard) requestsAnsweredCard.value = request_count || 0

      const calculateResponseTimeChange = (oldResponseTime, newResponseTime) => {
        const old_time = Number(oldResponseTime)
        const new_time = Number(newResponseTime)

        if (old_time == 0 && new_time == 0) {
          return { percentage: 0, is_increased: null }
        } else if (old_time == 0) {
          return { percentage: 100, is_increased: true }
        } else {
          const percentage = ((old_time - new_time) / old_time) * 100
          const is_increased = old_time > new_time

          return { percentage, is_increased }
        }
      }

      const percentageObj = calculateResponseTimeChange(
        Number(last_answer_in_min),
        Number(answer_in_min)
      )

      if (timeToAnswerRequestCard) {
        timeToAnswerRequestCard.isIncreased = percentageObj.is_increased
        timeToAnswerRequestCard.percent = percentageObj.percentage
        timeToAnswerRequestCard.value = `${Number(answer_in_min)} hours`
      }

      state.physicianDashboard.data = default_data
      state.physicianDashboard.loading = false
    })

    builder.addCase(get_physician_dashboard.rejected, (state) => {
      state.physicianDashboard.loading = false
    })

    // Get community dashboard
    builder.addCase(get_community_dashboard.pending, (state) => {
      state.communityDashboard.loading = true
    })
    builder.addCase(get_community_dashboard.fulfilled, (state, action) => {
      state.communityDashboard.data = action.payload?.response
      state.communityDashboard.loading = false
    })

    builder.addCase(get_community_dashboard.rejected, (state) => {
      state.communityDashboard.loading = false
    })
  }
})

export default dashboardSlice.reducer
