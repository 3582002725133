import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import {
  ActivityMonitoringIcon,
  DashboardIcon,
  DirectoryIcon,
  MyRequestsIcon,
  SettingsIcon
} from 'assets/svg'

export const synappAdminSidebarUtils = (t) => {
  let menuGroup = [
    {
      id: 'dashboard',
      title: t('AppModuleNames.Dashboard'),
      icon: DashboardIcon,
      url: AppRoutingConfig.DASHBOARD
    },
    {
      id: 'my-requests',
      title: t('AppModuleNames.AllRequests'),
      icon: MyRequestsIcon,
      url: AppRoutingConfig.MY_REQUESTS
    },
    {
      id: 'directory',
      title: t('AppModuleNames.Directory'),
      icon: DirectoryIcon,
      url: AppRoutingConfig.DIRECTORY
    },
    {
      id: 'activity-monitoring',
      title: t('AppModuleNames.ActivityMonitoring'),
      icon: ActivityMonitoringIcon,
      url: AppRoutingConfig.ACTIVITY_MONITORING
    },
    {
      id: 'settings',
      title: t('AppModuleNames.Settings'),
      icon: SettingsIcon,
      url: AppRoutingConfig.MY_PROFILE
    }
  ]

  return menuGroup
}
