import { createSlice } from '@reduxjs/toolkit'
import {
  get_settings_notifications,
  update_settings_notifications
} from '../services/notificationsService'

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: {
      loading: false,
      notifications_info: {}
    },
    updateNotifications: {
      loading: false
    }
  },
  extraReducers: (builder) => {
    // Get Settings Notifications
    builder.addCase(get_settings_notifications.pending, (state) => {
      state.notifications.loading = true
    })
    builder.addCase(get_settings_notifications.fulfilled, (state, action) => {
      state.notifications.loading = false
      state.notifications.notifications_info = action.payload?.response
    })
    builder.addCase(get_settings_notifications.rejected, (state) => {
      state.notifications.loading = false
    })

    // Update Settings Notifications
    builder.addCase(update_settings_notifications.pending, (state) => {
      state.updateNotifications.loading = true
    })
    builder.addCase(update_settings_notifications.fulfilled, (state) => {
      state.updateNotifications.loading = false
    })
    builder.addCase(update_settings_notifications.rejected, (state) => {
      state.updateNotifications.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = notificationSlice.actions

export default notificationSlice.reducer
