import Sidebar from 'components/common/sidebar'
import Navbar from 'components/common/navbar'
import Box from 'components/common/box'
import { useState } from 'react'
import { ArrowIcon } from 'assets/svg'
import { Outlet } from 'react-router-dom'
import { useTourComponentRefs } from 'context/TourRefsProvider'
import { physicianSidebarUtils } from 'utils/sidebarUtils/physicianSidebarUtils'
import { useTranslation } from 'react-i18next'
import { synappAdminSidebarUtils } from 'utils/sidebarUtils/synappAdminSidebarUtils'
import { roles } from '../../../constants'
import { useUserDetails } from 'context/UserDetailsProvider'
import { patientSidebarUtils } from 'utils/sidebarUtils/patientSidebarUtils'
import { authorEditorSidebarUtils } from 'utils/sidebarUtils/authorEditorSidebarUtils'
import './MainLayout.scss'

const MainLayout = () => {
  /* translation dependencies */
  const { t } = useTranslation()
  /* translation dependencies */

  /* sidebar collapsed dependencies */
  const [collapsed, setCollapsed] = useState(false)
  /* sidebar collapsed dependencies */

  /* tour routes */
  const ref = useTourComponentRefs()
  /* tour routes */

  /* sidebar dependencies */
  const renderRoleBasedSidebarUtil = {
    [roles.superAdmin]: synappAdminSidebarUtils(t),
    [roles.patient]: patientSidebarUtils(t, ref?.ref2, ref?.ref3, ref?.ref4, ref?.ref5),
    [roles.physician]: physicianSidebarUtils(
      t,
      ref?.ref2,
      ref?.ref3,
      ref?.ref4,
      ref?.ref5,
      ref?.ref6
    ),
    [roles.editor]: authorEditorSidebarUtils(t),
    [roles.author]: authorEditorSidebarUtils(t)
  }
  const { selectedRole } = useUserDetails()
  const menuGroup = renderRoleBasedSidebarUtil[selectedRole]
  /* sidebar dependencies */

  return (
    <div className="main-layout-container">
      <div
        onClick={() => setCollapsed(!collapsed)}
        className={`sidebar-button ${collapsed ? 'trigger-collapsed-zero' : 'trigger-collapsed-nonzero'} ${collapsed ? 'toggle-left' : 'toggle-right'}`}
      >
        <ArrowIcon />
      </div>

      <div className="sidebar-layout-container">
        <Sidebar collapsed={collapsed} menuGroup={menuGroup} />
      </div>
      <div
        className={`main-layout-wrapper ${collapsed ? 'main-layout-wrapper-expand' : 'main-layout-wrapper-collapse'}`}
      >
        <Navbar sidebarCollapsed={collapsed} setSidebarCollapsed={setCollapsed} />
        <Box>
          <Outlet />
        </Box>
      </div>
    </div>
  )
}

export default MainLayout
