import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import axiosInstance from 'axiosInstance'
import { translate } from 'i18n/i18n'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// Define the async thunk using createAsyncThunk
export const login = createAsyncThunk(
  'auth/begin_login',
  async ({ payload, navigate, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/begin-login`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        navigate(AppRoutingConfig.APP_URL_VERIFY_YOUR_IDENTITY)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get select speciality
export const get_select_specility = createAsyncThunk(
  'case/get_select_specility',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/te/speciality-list?page=${payload.page}&pageSize=${payload.pageSize}&search=${payload.searchSpeciality}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update specility to pin
export const update_specility_pin = createAsyncThunk(
  'case/update_specility_pin',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/speciality-pin-unpin`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get physician list
export const get_physician_list = createAsyncThunk(
  'case/get_physician_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/physician/list`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get profile info
export const get_physician_profile_info = createAsyncThunk(
  'case/get_physician_physician_profile_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/physician/detail`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get profile info
export const get_physician_profile_info_for_drafted_cases = createAsyncThunk(
  'case/get_physician_profile_info_for_drafted_cases',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/physician/detail`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update physician favorite card
export const update_physician_favorite_card = createAsyncThunk(
  'case/update_physician_favorite_card',
  async ({ payload, showNotification, dispatch, updateCard, id }, { rejectWithValue }) => {
    try {
      dispatch(updateCard(id))
      const response = await axiosInstance.post(`v1/physician/favorite-trigger`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update patient info
export const update_patient_info = createAsyncThunk(
  'case/update_patient_info',
  async (
    { payload, showNotification, handleNextClick, isSaveDraft, navigate },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/te/step-three`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        if (!isSaveDraft) {
          handleNextClick()
        } else {
          navigate(AppRoutingConfig.MY_LIBRARY)
        }
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get patient list
export const get_patient_list = createAsyncThunk(
  'case/get_patient_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/te/get-patients?page=${payload.pageNo}&pageSize=${payload.pageSize}&search=${payload.searchPatient}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.message.length > 0
              ? response?.data?.[0]?.message
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get instructions questionnaire
export const get_instructions_questionnaire = createAsyncThunk(
  'case/get_instructions_questionnaire',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/get-instructions-questionnaire`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update case info
export const update_case_info = createAsyncThunk(
  'case/update_case_info',
  async (
    {
      payload,
      showNotification,
      handleGetAmountForStepFivePaymentInfo,
      isSaveDraft,
      navigate,
      requestTypeValue
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/te/step-four`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        if (!isSaveDraft) {
          if (['Free', 'Social_Security'].includes(requestTypeValue)) {
            navigate(AppRoutingConfig.CASE_PAYMENT_CONFIRMATION, {
              state: {
                requestTypeValue
              }
            })
          } else {
            handleGetAmountForStepFivePaymentInfo()
          }
        } else {
          navigate(AppRoutingConfig.MY_LIBRARY)
        }
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get amount
export const get_amount = createAsyncThunk(
  'case/get_amount',
  async (
    { payload, handleNextClick, handleOpenRedirectToBillingInfoDialog },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/te/get-amount`, payload)
      if (response.status === 0) {
        handleNextClick()
        return { response: response.data }
      }
      if (response.status === 1) {
        // displayMultipleNotifications(response.data, showNotification)
        handleOpenRedirectToBillingInfoDialog()
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update ampunt
export const update_amount = createAsyncThunk(
  'case/update_amount',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/step-five`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })

        navigate(AppRoutingConfig.CASE_PAYMENT_CONFIRMATION)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
