import { Drawer, Tabs } from 'antd'
// import ConditionalWrapper from '../conditional-wrapper'
import NotificationWidgetHeader from './NotificationWidgetHeader'
import './NotificationWidget.scss'
// import { tabItems } from './Data'
import { tabItems } from './notificationWidgetTabs'

const NotificationWidget = ({ isCollapsed, setCollapsed, showContent }) => {
  return (
    <div
      className={`notification-widget-container ${isCollapsed ? 'show-notification-widget-card' : 'hide-notification-widget-card'}`}
    >
      <ConditionalWrapper
        isCollapsed={isCollapsed}
        setCollapsed={setCollapsed}
        showContent={showContent}
      >
        <Tabs defaultActiveKey="1" items={tabItems} className="notification-tab-style" />
      </ConditionalWrapper>
    </div>
  )
}

const ConditionalWrapper = ({ children, isCollapsed, setCollapsed, showContent }) => {
  const onClose = () => {
    setCollapsed((prevState) => !prevState)
  }
  return !showContent ? (
    <Drawer
      title={<NotificationWidgetHeader showContent={showContent} onClose={onClose} />}
      placement={'right'}
      onClose={onClose}
      open={isCollapsed}
      closable={false}
    >
      {children}
    </Drawer>
  ) : (
    <>
      <NotificationWidgetHeader showContent={showContent} />
      {children}
    </>
  )
}

export default NotificationWidget
