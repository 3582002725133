export class AppRoutingConfig {
  /* USER SESSION */
  static COMPONENTS = '/components'
  // l1
  static APP_URL_LOGIN = '/login'
  // l2
  static APP_URL_VERIFY_YOUR_IDENTITY = '/verify-your-identity'
  // l3
  static APP_URL_LOGIN_CONFIRMATION = '/login-confirmation'
  // s1
  static APP_URL_SIGN_UP = '/sign-up'
  // s2
  static APP_URL_CHOOSE_YOUR_IDENTITY = '/choose-your-identity'
  // s3
  // choose rpps or non rpps
  static APP_URL_SELECT_SIGNUP_METHOD = '/select-signup-method'
  // s4 (optional) with rpps
  static APP_URL_SIGN_UP_WITH_RPPS = '/sign-up-with-rpps'
  // s4 continue without rpps  / s5 with rpps
  static APP_URL_ACCOUNT_INFORMATION = '/account-information'
  static APP_URL_PATIENT_ACCOUNT_INFORMATION = '/patient-account-information'
  // s5/s6 (optional) with or without rpps
  static APP_URL_CREATE_PASSWORD = '/create-password'
  // s6/s7 depening on rpps
  static APP_URL_SIGNED_UP_CONFIRMATION = '/signed-up-confirmation'

  static APP_URL_ROOT = '/'
  static APP_URL_ROOT_ALL = '/*'
  static APP_URL_DEFAULT_BASE_REDIRECT = '*'
  static APP_URL_NOT_PERMITTED = '/not-permitted'

  // PHYSICIAN
  static PHYSICIAN = '/physician'

  /* USER SESSION */

  // HOME
  static HOME = '/home'
  static UPGRADE_SUBSCRIPTION_PLAN = '/upgrade-subscription-plan'
  // DASHBOARD
  static DASHBOARD = '/dashboard'

  // MY REQUESTS
  static MY_REQUESTS = '/my-requests'

  // My PATIENTS
  static MY_PATIENTS = '/my-patients'

  // DIRECTORY
  static DIRECTORY = '/directory'

  // MY COMMUNITY
  static MY_COMMUNITY = '/my-community'

  // MY LIBRARY
  static MY_LIBRARY = '/my-library'

  // APPOINTMENTS
  static APPOINTMENTS = '/appointments'

  // SETTINGS
  static SETTINGS = '/settings'
  static PROFILE_INFORMATION = this.SETTINGS + '/profile-information'
  static PAYMENT_INFORMATION = this.SETTINGS + '/payment-information'
  static ACCOUNT_INFORMATION = this.SETTINGS + '/account-information'
  static ACCOUNT_SETTINGS = this.SETTINGS + '/account-settings'
  static CREATE_TEMPLATE = this.ACCOUNT_SETTINGS + '/create-template'
  static PROFESSIONAL_INFORMATION = this.SETTINGS + '/professional-information'
  static REQUEST_MANAGEMENT = this.SETTINGS + '/request-management'
  static NOTIFICATIONS = this.SETTINGS + '/notifications'
  static BILLING_INFO = this.SETTINGS + '/billing-info'
  static STORAGE_AND_DATA = this.SETTINGS + '/storage-and-data'
  static STORAGE = this.SETTINGS + '/storage'
  static TEST_USERS = this.STORAGE_AND_DATA + '/test-users'
  static TEST_PHYSICIANS = this.STORAGE + '/test-physicians'

  // SYNAPP-ADMIN SETTINGS
  static MY_PROFILE = this.SETTINGS + '/my-profile'
  static ADMINISTRATION = this.SETTINGS + '/administration'
  static ALL_USERS = this.ADMINISTRATION + '/all-users'
  static ADD_USER_MANUALLY = this.ALL_USERS + '/add-user-manually'
  static MEDICAL_SETTINGS = this.ADMINISTRATION + '/medical-settings'
  static QUESTION_LIBRARY = this.ADMINISTRATION + '/question-library'
  static INSTRUCTIONS = this.ADMINISTRATION + '/instructions'
  static USER_ROLES_AND_PERMISSIONS = this.ADMINISTRATION + '/user-roles-and-permissions'
  static ADD_USER_MANUALLY_CONFIRMATION = this.SETTINGS + '/add-user-manually-confirmation'
  static CONFIGURE_POLICIES = this.SETTINGS + '/configure-policies'
  static VIEW_PHYSICIAN_PROFILE = this.PHYSICIAN + '/view-profile'
  static VIEW_ACTIVITY_LOGS = this.PHYSICIAN + '/view-activity-logs'
  static CREATE_COMMUNITY = this.ALL_USERS + '/create-community'
  static ADD_COMMUNITY_MEMBER_CONFIRMATION = this.SETTINGS + '/add-community-member-confirmation'

  // ACTIVITY MONITORING
  static ACTIVITY_MONITORING = '/activity-monitoring'
  /* ADMIN MODULES */

  /* CREATE CASE */
  static CREATE_CASE = '/create-case'
  static CASE_PAYMENT_CONFIRMATION = '/case-payment-confirmation'
  static DOCUMENT_UPLOAD = '/room/:peerId'
  static DOCUMENT_SO_UPLOAD = '/so-room/:peerId'

  // Second Opinion
  static CREATE_SECOND_OPINION_CASE = '/create-request'

  /* Preview Profiles */
  static PREVIEW_PROFILE = '/view-profile/'
  static VIEW_PROFILE = this.PREVIEW_PROFILE + ':profileId?'
  static PREVIEW_COMMUNITY = '/view-community/'
  static VIEW_COMMUNITY = this.PREVIEW_COMMUNITY + ':communityId?'

  static VIEW_CASE_REQUEST = '/all-requests/view-request/'
  static VIEW_CASE_REQUEST_BY_ID = this.VIEW_CASE_REQUEST + ':requestId'

  static VIEW_SO_CASE_REQUEST = '/all-requests/view-so-request/'
  static VIEW_SO_CASE_REQUEST_BY_ID = this.VIEW_SO_CASE_REQUEST + ':requestId'

  static RESEARCHER_CASE = '/researcher-case'
}
