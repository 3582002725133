import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { translate } from 'i18n/i18n'

// get settings notifications
export const get_settings_notifications = createAsyncThunk(
  'notifications/get_settings_notifications',
  async ({ showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/profile/notification/config/get`)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update settings notifications
export const update_settings_notifications = createAsyncThunk(
  'notifications/update_settings_notifications',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/notification/config`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
