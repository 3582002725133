import { createSlice } from '@reduxjs/toolkit'
import {
  connect_to_stripe,
  get_billing_info,
  update_billing_info
} from '../services/billingInfoService'

export const billingInfoSlice = createSlice({
  name: 'billingInfo',
  initialState: {
    billingInfo: {
      loading: false,
      user_billing_info: {}
    },
    updateBillingInfo: {
      loading: false
    },
    connectToStripe: {
      loading: false
    }
  },
  extraReducers: (builder) => {
    // Get Billing Info
    builder.addCase(get_billing_info.pending, (state) => {
      state.billingInfo.loading = true
    })
    builder.addCase(get_billing_info.fulfilled, (state, action) => {
      state.billingInfo.loading = false
      state.billingInfo.user_billing_info = action.payload?.response
    })
    builder.addCase(get_billing_info.rejected, (state) => {
      state.billingInfo.loading = false
    })

    // Update Billing Info
    builder.addCase(update_billing_info.pending, (state) => {
      state.updateBillingInfo.loading = true
    })

    builder.addCase(update_billing_info.fulfilled, (state) => {
      state.updateBillingInfo.loading = false
    })

    builder.addCase(update_billing_info.rejected, (state) => {
      state.updateBillingInfo.loading = false
    })

    // Update Billing Info
    builder.addCase(connect_to_stripe.pending, (state) => {
      state.connectToStripe.loading = true
    })

    builder.addCase(connect_to_stripe.fulfilled, (state) => {
      state.connectToStripe.loading = false
    })

    builder.addCase(connect_to_stripe.rejected, (state) => {
      state.connectToStripe.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = professionalInfoSlice.actions

export default billingInfoSlice.reducer
