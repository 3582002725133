import { Flex, Typography } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'
import { translate } from 'i18n/i18n'

export const optionsData = [
  {
    label: (
      <Typography.Title level={4} style={{ color: 'var(--cyan-blue)' }}>
        Physicians
      </Typography.Title>
    ),
    title: 'Physicians',
    options: [
      {
        label: (
          <Flex gap={'0.5rem'} align="center">
            <ClockCircleOutlined /> Harley S
          </Flex>
        ),
        value: 'Harley S'
      },
      {
        label: (
          <Flex gap={'0.5rem'} align="center">
            <ClockCircleOutlined /> Harley S
          </Flex>
        ),
        value: 'Harley S'
      }
    ]
  },
  {
    label: (
      <Typography.Title level={4} style={{ color: 'var(--cyan-blue)' }}>
        Patients
      </Typography.Title>
    ),
    title: 'Patients',
    options: [
      {
        label: (
          <Flex gap={'0.5rem'} align="center">
            <ClockCircleOutlined /> Harley S
          </Flex>
        ),
        value: 'Harley S'
      },
      {
        label: (
          <Flex gap={'0.5rem'} align="center">
            <ClockCircleOutlined /> Harley S
          </Flex>
        ),
        value: 'Harley S'
      }
    ]
  }
]
export const languageData = [
  {
    key: '1',
    label: translate('CommonUtils.English')
  },
  {
    key: '2',
    label: translate('CommonUtils.French')
  }
]
