import { createSlice } from '@reduxjs/toolkit'
import {
  add_comment,
  add_remove_bookmark,
  add_remove_so_bookmark,
  add_so_comment,
  get_comments,
  get_community_assignee_list,
  get_community_te_my_requests,
  get_my_requests,
  request_assignee,
  get_my_so_requests,
  get_so_comments,
  update_request_status,
  update_so_request_status,
  view_requests,
  view_so_requests
} from '../services/myRequestsService'

export const myRequestsSlice = createSlice({
  name: 'case',
  initialState: {
    loading: false,
    myRequestsList: {
      loading: false,
      data: []
    },
    requestDetails: {
      loading: false,
      data: {}
    },
    commentsList: {
      loading: false,
      data: []
    },
    updateRequestStatus: {
      loading: false
    },
    addComment: {
      loading: false
    },
    addRemoveBookmark: {
      loading: false
    },
    communityMyRequestsList: {
      data: null,
      loading: false
    },
    communityAssigneeList: {
      data: null,
      loading: false
    },
    requestAssignee: {
      loading: false
    },
    // ================= SO CASE =================== //
    mySoRequestList: {
      loading: false
    },
    requesSotDetails: {
      loading: false,
      data: {}
    },
    commentsSoList: {
      loading: false,
      data: []
    },
    updateSoRequestStatus: {
      loading: false
    },
    addSoComment: {
      loading: false
    },
    addRemoveSoBookmark: {
      loading: false
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get my requests
    builder.addCase(get_my_requests.pending, (state) => {
      state.myRequestsList.loading = true
    })
    builder.addCase(get_my_requests.fulfilled, (state, action) => {
      state.myRequestsList.loading = false
      state.myRequestsList.data = action.payload?.response
    })
    builder.addCase(get_my_requests.rejected, (state) => {
      state.myRequestsList.loading = false
    })

    // Get community te my requests
    builder.addCase(get_community_te_my_requests.pending, (state) => {
      state.communityMyRequestsList.loading = true
    })
    builder.addCase(get_community_te_my_requests.fulfilled, (state, action) => {
      state.communityMyRequestsList.loading = false
      state.communityMyRequestsList.data = action.payload?.response
    })
    builder.addCase(get_community_te_my_requests.rejected, (state) => {
      state.communityMyRequestsList.loading = false
    })

    // Get community assignee list
    builder.addCase(get_community_assignee_list.pending, (state) => {
      state.communityAssigneeList.loading = true
    })
    builder.addCase(get_community_assignee_list.fulfilled, (state, action) => {
      state.communityAssigneeList.loading = false
      state.communityAssigneeList.data = action.payload?.response
    })
    builder.addCase(get_community_assignee_list.rejected, (state) => {
      state.communityAssigneeList.loading = false
    })

    // request assignee
    builder.addCase(request_assignee.pending, (state) => {
      state.requestAssignee.loading = true
    })
    builder.addCase(request_assignee.fulfilled, (state) => {
      state.requestAssignee.loading = false
    })
    builder.addCase(request_assignee.rejected, (state) => {
      state.requestAssignee.loading = false
    })

    // Get comments
    builder.addCase(get_comments.pending, (state) => {
      state.commentsList.loading = true
    })
    builder.addCase(get_comments.fulfilled, (state, action) => {
      state.commentsList.loading = false
      state.commentsList.data = action.payload?.response
    })
    builder.addCase(get_comments.rejected, (state) => {
      state.commentsList.loading = false
    })

    // View requests
    builder.addCase(view_requests.pending, (state) => {
      state.requestDetails.loading = true
    })
    builder.addCase(view_requests.fulfilled, (state, action) => {
      state.requestDetails.loading = false
      state.requestDetails.data = action.payload?.response
    })
    builder.addCase(view_requests.rejected, (state) => {
      state.requestDetails.loading = false
    })

    // update request status
    builder.addCase(update_request_status.pending, (state) => {
      state.updateRequestStatus.loading = true
    })
    builder.addCase(update_request_status.fulfilled, (state) => {
      state.updateRequestStatus.loading = false
    })
    builder.addCase(update_request_status.rejected, (state) => {
      state.updateRequestStatus.loading = false
    })

    // add comment
    builder.addCase(add_comment.pending, (state) => {
      state.addComment.loading = true
    })
    builder.addCase(add_comment.fulfilled, (state) => {
      state.addComment.loading = false
    })
    builder.addCase(add_comment.rejected, (state) => {
      state.addComment.loading = false
    })

    // add remove bookmark
    builder.addCase(add_remove_bookmark.pending, (state) => {
      state.addRemoveBookmark.loading = true
    })
    builder.addCase(add_remove_bookmark.fulfilled, (state) => {
      state.addRemoveBookmark.loading = false
    })
    builder.addCase(add_remove_bookmark.rejected, (state) => {
      state.addRemoveBookmark.loading = false
    })

    // ==================== SO CASE ====================== //

    // Get my so requests
    builder.addCase(get_my_so_requests.pending, (state) => {
      state.mySoRequestList.loading = true
    })
    builder.addCase(get_my_so_requests.fulfilled, (state, action) => {
      state.mySoRequestList = { ...state.mySoRequestList, ...action?.payload?.response }
      state.mySoRequestList.loading = false
    })
    builder.addCase(get_my_so_requests.rejected, (state) => {
      state.mySoRequestList.loading = false
    })

    // View SO requests
    builder.addCase(view_so_requests.pending, (state) => {
      state.requesSotDetails.loading = true
    })
    builder.addCase(view_so_requests.fulfilled, (state, action) => {
      state.requesSotDetails.loading = false
      state.requesSotDetails.data = action.payload?.response
    })
    builder.addCase(view_so_requests.rejected, (state) => {
      state.requesSotDetails.loading = false
    })

    // Get So comments
    builder.addCase(get_so_comments.pending, (state) => {
      state.commentsSoList.loading = true
    })
    builder.addCase(get_so_comments.fulfilled, (state, action) => {
      state.commentsSoList.loading = false
      state.commentsSoList.data = action.payload?.response
    })
    builder.addCase(get_so_comments.rejected, (state) => {
      state.commentsSoList.loading = false
    })

    // update so request status
    builder.addCase(update_so_request_status.pending, (state) => {
      state.updateSoRequestStatus.loading = true
    })
    builder.addCase(update_so_request_status.fulfilled, (state) => {
      state.updateSoRequestStatus.loading = false
    })
    builder.addCase(update_so_request_status.rejected, (state) => {
      state.updateSoRequestStatus.loading = false
    })

    // add so comment
    builder.addCase(add_so_comment.pending, (state) => {
      state.addSoComment.loading = true
    })
    builder.addCase(add_so_comment.fulfilled, (state) => {
      state.addSoComment.loading = false
    })
    builder.addCase(add_so_comment.rejected, (state) => {
      state.addSoComment.loading = false
    })

    // add remove so bookmark
    builder.addCase(add_remove_so_bookmark.pending, (state) => {
      state.addRemoveSoBookmark.loading = true
    })
    builder.addCase(add_remove_so_bookmark.fulfilled, (state) => {
      state.addRemoveSoBookmark.loading = false
    })
    builder.addCase(add_remove_so_bookmark.rejected, (state) => {
      state.addRemoveSoBookmark.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = myRequestsSlice.actions

export default myRequestsSlice.reducer
