import Navbar from 'components/common/navbar'
import { Outlet } from 'react-router-dom'
import './CaseLayout.scss'

const CaseLayout = () => {
  return (
    <div className="case-layout-wrapper">
      <Navbar isPrimaryLayout />
      <Outlet />
    </div>
  )
}

export default CaseLayout
