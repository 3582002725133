import { createSlice } from '@reduxjs/toolkit'
import {
  get_diploma_list,
  get_profile_info,
  profile_validation,
  update_profile_info,
  update_synapp_admin_profile_info
} from '../services/profileInfoService'

export const profileInfoSlice = createSlice({
  name: 'profileInfo',
  initialState: {
    diplomaList: [],
    loading: false,
    profileInfo: {
      loading: false,
      user_profile_info: null
    },
    updateProfileInfo: {
      loading: false
    },
    updateSynappProfileInfo: {
      loading: false
    },
    profileValidation: {
      loading: false
    }
  },
  reducers: {
    clearProfileInfo: (state) => {
      state.profileInfo.loading = false
      state.profileInfo.user_profile_info = null
    }
  },
  extraReducers: (builder) => {
    // Get diploma list
    builder.addCase(get_diploma_list.pending, (state) => {
      state.loading = true
    })
    builder.addCase(get_diploma_list.fulfilled, (state, action) => {
      state.loading = false
      state.diplomaList = action.payload?.response?.data?.map((list) => {
        return {
          label: list.display_name,
          value: list.value
        }
      })
    })
    builder.addCase(get_diploma_list.rejected, (state) => {
      state.loading = false
    })

    // Get Profile Info
    builder.addCase(get_profile_info.pending, (state) => {
      state.profileInfo.loading = true
    })
    builder.addCase(get_profile_info.fulfilled, (state, action) => {
      state.profileInfo.loading = false
      state.profileInfo.user_profile_info = action.payload?.response
    })
    builder.addCase(get_profile_info.rejected, (state) => {
      state.profileInfo.loading = false
    })

    // Update Profile Info
    builder.addCase(update_profile_info.pending, (state) => {
      state.updateProfileInfo.loading = true
    })

    builder.addCase(update_profile_info.fulfilled, (state) => {
      state.updateProfileInfo.loading = false
    })

    builder.addCase(update_profile_info.rejected, (state) => {
      state.updateProfileInfo.loading = false
    })

    // Update Synapp Profile Info
    builder.addCase(update_synapp_admin_profile_info.pending, (state) => {
      state.updateSynappProfileInfo.loading = true
    })

    builder.addCase(update_synapp_admin_profile_info.fulfilled, (state) => {
      state.updateSynappProfileInfo.loading = false
    })

    builder.addCase(update_synapp_admin_profile_info.rejected, (state) => {
      state.updateSynappProfileInfo.loading = false
    })

    // Update Synapp Profile Info
    builder.addCase(profile_validation.pending, (state) => {
      state.profileValidation.loading = true
    })

    builder.addCase(profile_validation.fulfilled, (state) => {
      state.profileValidation.loading = false
    })

    builder.addCase(profile_validation.rejected, (state) => {
      state.profileValidation.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { clearProfileInfo } = profileInfoSlice.actions

export default profileInfoSlice.reducer
