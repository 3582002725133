import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'
// import { createEncryptedValue } from 'assets/config/AppEncryptDecryptConfig'

// get my telexpertise draft cases list
export const get_my_telexpertise_draft_cases = createAsyncThunk(
  'myLLibrary/get_my_telexpertise_draft_cases',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/te/draft/cases-list?page=${payload?.page || ''}&pageSize=${payload?.pageSize || ''}&search=${payload?.search || ''}`
      )
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// view telexpertise draft cases
export const view_telexpertise_draft_cases = createAsyncThunk(
  'myLLibrary/view_telexpertise_draft_cases',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/draft/view`, payload)
      if (response.status === 0) {
        const decyptedCurrentStepId = response?.data?.current_step
        const decyptedExpertId = response?.data?.expert_data?.user_no
        navigate(
          `${AppRoutingConfig.CREATE_CASE}?current_step_id=${decyptedCurrentStepId}&expert_id=${decyptedExpertId}`
        )
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get my telexpertise bookmarked requests list
export const get_my_telexpertise_bookmarked_requests = createAsyncThunk(
  'myLLibrary/get_my_telexpertise_bookmarked_requests',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/te/bookmark/cases-list?page=${payload?.page || ''}&pageSize=${payload?.pageSize || ''}&search=${payload?.search || ''}`,
        payload
      )
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */
/* --------------------------------------- SECOND OPINION --------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */

// get my so bookmarked requests list
export const get_my_so_bookmarked_requests = createAsyncThunk(
  'myLibrary/get_my_so_bookmarked_requests',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/so/bookmark/cases-list?page=${payload?.page || ''}&pageSize=${payload?.pageSize || ''}&search=${payload?.search || ''}`,
        payload
      )
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
