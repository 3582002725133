import CryptoJS from 'crypto-js'

export const encryptData = (key, data) => {
  const encriptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_LS_ENCRYPTION_KEY
  ).toString()
  localStorage.setItem(key, encriptedData)
}

export const decryptData = (key) => {
  const encryptedData = localStorage.getItem(key)
  let decryptedData = null
  if (encryptedData && encryptedData !== '') {
    const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_LS_ENCRYPTION_KEY)
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  }
  return decryptedData
}

export const createEncryptedValue = (value) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    process.env.REACT_APP_LS_ENCRYPTION_KEY
  ).toString()
  return encrypted
}

export const decryptEncryptedValue = (value) => {
  let decryptedData = null
  if (value && value !== '') {
    const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_LS_ENCRYPTION_KEY)
    decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    console.log(decryptedData, 'decryptedData')
  }
  return decryptedData
}
